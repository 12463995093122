import template from "./base.html";
import en from "./en.translation.json";
import es from "./es.translation.json";
import logoUrl from "../../../../../assets/harvust-logo-008.svg";
import waflaLogoUrl from "./wafla_logo.png";

class PublicContractListController {
    constructor() {
        this.LOGO_URL = logoUrl;
        this.WAFLA_LOGO_URL = waflaLogoUrl;
    }
}

export default {
    controller: PublicContractListController,
    bindings: {
        activeContracts: "<",
        doneContracts: "<",
        grower: "<",
    },
    template: template,
    name: "publicContractList",
    translations: { en, es },
};
