import {
    AGREE_TO_ESIGN,
    I_9_DOCUMENT_PACKAGE_SID,
    SIGNER,
} from "../../constants";
import signStartPaperworkTemplate from "./sign_start.html";
import en from "./en.translation.json";
import es from "./es.translation.json";

class SignStartPaperworkController {
    /* @ngInject */
    constructor($state) {
        this.$state = $state;
        this.I_9_DOCUMENT_PACKAGE_SID = I_9_DOCUMENT_PACKAGE_SID;
        this.SIGNER = SIGNER;
    }

    completeAndRedirect() {
        let ctrl = this;
        ctrl.showSpinner = true;
        ctrl.signatureRequest.addAuditTrailEventOLD(AGREE_TO_ESIGN);
        ctrl.$state.go(ctrl.redirectState);
    }
}

export default {
    bindings: {
        signatureRequest: "<",
        redirectState: "<",
        user: "<",
    },
    controller: SignStartPaperworkController,
    template: signStartPaperworkTemplate,
    name: "signStartPaperwork",
    translations: { en, es },
};
