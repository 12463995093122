"use strict";

export const COMPLETE = "completed";
export const PENDING = "pending";
export const CANCELLED = "cancelled";

export const GROWER_CERTIFIED = "grower";
export const SELF_CERTIFIED = "self";

export const SAFETY_MEETING = "safetymeeting";
export const GENERAL_TRAINING = "general";

export const FAIL = "fail";
export const PASS = "pass";
