"use strict";

import angular from "angular";
import core from "../../core.module";
import { AuthService } from "./auth.services";
import _ from "lodash";
import components from "./components";
import "angular-local-storage";
import "angular-jwt";
import { addBreadcrumb } from "@sentry/browser";

let module = angular
    .module("common.auth", [core, "LocalStorageModule", "angular-jwt"])
    .service("AuthService", AuthService)
    .config(function (jwtOptionsProvider, $httpProvider) {
        "ngInject";

        // Auth
        jwtOptionsProvider.config({
            tokenGetter: function (options, AuthService) {
                "ngInject";
                addBreadcrumb({
                    category: "auth",
                    message: "Firing tokenGetter for URL: " + options.url,
                });
                return AuthService.tokenGetter();
            },
            unauthenticatedRedirector: function (AuthService) {
                "ngInject";
                AuthService.redirectToLogin();
            },
            whiteListedDomains: [process.env.WHITELIST_API_DOMAIN],
        });
        $httpProvider.interceptors.push("jwtInterceptor");
    });

_.forEach(components, function (component) {
    module.component(component.name, component);
});

module.config(function ($translateProvider) {
    "ngInject";
    _.forEach(components, function (component) {
        _.forEach(component.translations, function (table, language) {
            $translateProvider.translations(language, table);
        });
    });
});

export default module;
