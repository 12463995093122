import { ENGLISH, SPANISH } from "../../../../app.constants";
import _ from "lodash";
import createAccountTemplate from "./create_account.html";
import { FEMALE, MALE } from "../../common.constants";
import en from "./en.json";
import es from "./es.json";

class CreateAccountController {
    /* @ngInject */
    constructor(
        AccountService,
        GuessLanguageService,
        $state,
        $scope,
        GuessGenderService,
        $timeout,
        $document,
    ) {
        this.AccountService = AccountService;
        this.GuessLanguageService = GuessLanguageService;
        this.GuessGenderService = GuessGenderService;
        this.$state = $state;
        this.SPANISH = SPANISH;
        this.ENGLISH = ENGLISH;
        this.FEMALE = FEMALE;
        this.MALE = MALE;
        this.$timeout = $timeout;
        this.$document = $document;
        let ctrl = this;

        ctrl.debouncedGuessLanguageCall = _.debounce(function (
            newValues,
            oldValues,
            scope,
        ) {
            let [first, last] = newValues;
            if (
                scope.$ctrl.createUserForm.preferred_language.$pristine &&
                first &&
                last
            ) {
                ctrl.GuessLanguageService.guess(first, last).then(
                    function (response) {
                        let language_guess = response.data.guessed_language;
                        if (_.isNil(language_guess)) {
                            language_guess = SPANISH;
                        }
                        ctrl.newMember.preferred_language = language_guess;
                    },
                    function (reason) {},
                );
            }
            if (scope.$ctrl.createUserForm.gender.$pristine && first) {
                ctrl.GuessGenderService.guess(first).then(
                    function (response) {
                        ctrl.newMember.gender = response.data.guessed_gender;
                    },
                    function (reason) {},
                );
            }
        }, 500);

        $scope.$watchGroup(
            ["$ctrl.newMember.first_name", "$ctrl.newMember.last_name"],
            ctrl.debouncedGuessLanguageCall,
        );
    }

    $onInit() {
        let ctrl = this;
        ctrl.$timeout(function () {
            try {
                ctrl.$document[0].getElementsByName("mobile")[0].focus();
            } catch (e) {}
        });
    }

    $onDestroy() {
        let ctrl = this;
        ctrl.debouncedGuessLanguageCall.cancel();
    }

    createMember(newUser) {
        let ctrl = this;
        ctrl.showSpinner = true;
        let inviteParams = {
            first_name: newUser.first_name,
            last_name: newUser.last_name,
            preferred_language: newUser.preferred_language,
            gender: newUser.gender,
        };
        let mobile = _.trim(newUser.mobile);
        if (mobile) {
            inviteParams.mobile = mobile;
        }
        ctrl.AccountService.invite(inviteParams).then(
            function (user) {
                ctrl.callback({ user });
            },
            function (reason) {
                ctrl.showSpinner = false;
                ctrl.errors = reason.data;
            },
        );
    }
}
export default {
    bindings: { newMember: "<", callback: "&" },
    controller: CreateAccountController,
    template: createAccountTemplate,
    translations: { en, es },
    name: "createAccount",
};
