import en from "./en.translation.json";
import es from "./es.translation.json";
import takeProfilePictureTemplate from "./take_profile_picture.html";
import _ from "lodash";
import mixpanel from "mixpanel-browser";

import {
    UPLOAD_PENDING,
    UPLOAD_SUCCESS,
    FACING_MODE_ENVIRONMENT,
    FACING_MODE_USER,
} from "../../../common/common.constants";
import { captureMessage } from "@sentry/browser";

export const OVERLAY_TIMEOUT = 3000;

class TakeProfilePictureController {
    /* @ngInject */
    constructor(
        $http,
        ToastService,
        $window,
        $state,
        $timeout,
        $translate,
        $q,
        CanvasService,
    ) {
        this.$http = $http;
        this.ToastService = ToastService;
        this.$window = $window;
        this.$state = $state;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this.$q = $q;
        this.CanvasService = CanvasService;

        this.UPLOAD_PENDING = UPLOAD_PENDING;
        this.UPLOAD_SUCCESS = UPLOAD_SUCCESS;
        this.cameraAllowed = true;
    }

    captureVideo() {
        let ctrl = this;
        let options = {
            video: {
                width: { ideal: 1920 },
                height: { ideal: 1080 },
                facingMode: ctrl.selfie
                    ? FACING_MODE_USER
                    : FACING_MODE_ENVIRONMENT,
                frameRate: {
                    ideal: 30,
                },
            },
            audio: false,
        };
        ctrl.$q
            .when(ctrl.$window.navigator.mediaDevices.getUserMedia(options))
            .then(
                function (stream) {
                    console.log("loaded camera!");
                    ctrl.cameraAllowed = true;
                    ctrl.video.srcObject = stream;
                    ctrl.video.play();
                },
                function () {
                    ctrl.cameraAllowed = false;
                },
            );
    }

    $onInit() {
        let ctrl = this;
        if (ctrl.growerMembershipSid) {
            ctrl.video = document.getElementById("video");
            ctrl.captureVideo();
        } else {
            ctrl.uploadStatus = UPLOAD_SUCCESS;
        }
    }

    $onDestroy() {
        let ctrl = this;
        if (ctrl.video && ctrl.video.srcObject) {
            ctrl.endVideoCapture();
        }
    }

    endVideoCapture() {
        let ctrl = this;
        for (let track of ctrl.video.srcObject.getTracks()) {
            track.stop();
        }
    }

    takePhoto() {
        let ctrl = this;
        let canvas = document.getElementById("canvas");
        let video = ctrl.video;

        ctrl.uploadStatus = UPLOAD_PENDING;

        // Set image
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        let context = canvas.getContext("2d", { alpha: false });
        video.pause();
        context.drawImage(video, 0, 0);
        ctrl.CanvasService.getBlob(canvas).then(function (blob) {
            let fd = new FormData();
            console.log("file size: " + blob.size);
            fd.append("file", blob);
            fd.append("upload_preset", process.env.WORKER_PHOTO_UPLOAD_PRESET);
            fd.append(
                "context",
                `grower_membership_sid=${ctrl.growerMembershipSid}`,
            );
            ctrl.$q
                .when(
                    fetch("https://api.cloudinary.com/v1_1/harvust/upload", {
                        method: "POST",
                        body: fd,
                    }),
                )
                .then(
                    function (rawRes) {
                        console.log(rawRes);
                        if (rawRes.ok) {
                            rawRes.json().then(function (data) {
                                let [validFace, toastMessage] =
                                    isFaceValid(data);
                                if (validFace) {
                                    ctrl.uploadStatus = UPLOAD_SUCCESS;
                                    ctrl.endVideoCapture();
                                    ctrl.$state.go(".", {
                                        user_sid: undefined,
                                    });
                                } else {
                                    ctrl.$translate(toastMessage).then(
                                        function (translatedMessage) {
                                            ctrl.errorMessage =
                                                translatedMessage;
                                        },
                                    );
                                    ctrl.$timeout(function () {
                                        console.log("error over, reloading!");
                                        ctrl.endVideoCapture();
                                        ctrl.$state.reload();
                                    }, OVERLAY_TIMEOUT);
                                }
                                mixpanel.track("Took profile picture");
                            });
                        } else {
                            rawRes.json().then(function (data) {
                                captureMessage(
                                    "Error uploading profile picture",
                                    {
                                        contexts: {
                                            error: { data, rawRes },
                                        },
                                    },
                                );
                                ctrl.ToastService.create(
                                    "takeProfilePicture.UPLOAD_ERROR",
                                );
                                ctrl.$state.reload();
                            });
                        }
                    },
                    function (err) {
                        captureMessage("Error uploading profile picture", {
                            contexts: {
                                error: err,
                            },
                        });
                        ctrl.ToastService.create(
                            "takeProfilePicture.UPLOAD_ERROR",
                        );
                        ctrl.$state.reload();
                    },
                );
        });
    }
}

export default {
    bindings: { growerMembershipSid: "<", selfie: "<" },
    name: "takeProfilePicture",
    controller: TakeProfilePictureController,
    template: takeProfilePictureTemplate,
    translations: { en, es },
};

function isFaceValid(data) {
    let adv_face = data.info.detection.adv_face;
    let hasFaceData = _.has(adv_face, "data");
    if (!hasFaceData) {
        return [false, "takeProfilePicture.NO_FACE_DETECTED"];
    } else {
        let attributes = data.info.detection.adv_face.data[0].attributes;
        let hasSunglasses = attributes.glasses === "Sunglasses";
        let occlusions = attributes.occlusion;
        let eyesOccluded = occlusions.eyeOccluded;
        let mouthOccluded = occlusions.mouthOccluded;
        if (hasSunglasses) {
            return [false, "takeProfilePicture.SUNGLASSES_DETECTED"];
        } else if (eyesOccluded) {
            return [false, "takeProfilePicture.EYES_OCCLUDED"];
        } else if (mouthOccluded) {
            return [false, "takeProfilePicture.MOUTH_OCCLUDED"];
        } else {
            return [true, undefined];
        }
    }
}
