"use strict";

import {
    BAD_REQUEST,
    FORBIDDEN,
    NOT_FOUND,
    UNPROCESSABLE_ENTITY,
} from "./common/common/common.constants";
import mixpanel from "mixpanel-browser";
import {
    addBreadcrumb,
    captureMessage,
    captureException,
} from "@sentry/browser";
import _ from "lodash";
import { cookiesRequiredState, homescreenState } from "./app.routes";
import { RejectType } from "@uirouter/core/lib-esm/transition/rejectFactory";

/* @ngInject */
export default function setup(
    $urlRouter,
    $location,
    $anchorScroll,
    AccountService,
    $q,
    AuthService,
    authManager,
    ToastService,
    ManagementMembershipService,
    GrowerManagementMembershipService,
    $transitions,
    localStorageService,
    $state,
    $urlService,
    $document,
    BeamsService,
    $rootScope,
    $window,
    GrowerMembershipService,
) {
    console.log("in the run!");
    if (
        !localStorageService.isSupported ||
        !localStorageService.cookie.isSupported
    ) {
        $state.go(cookiesRequiredState);
    }
    AuthService.start()
        .then(
            function (token) {
                addBreadcrumb({
                    category: "auth",
                    message: "Picked Token: " + token,
                });
                AuthService.setRefreshToken(token);
                AuthService.setLoggedInFlag();
                return AuthService.getAuthenticatedUserSid().then(
                    function (userSid) {
                        AccountService.retrieve(userSid).then(
                            function (account) {
                                account.updateSentry();
                                account.updateMixpanel();
                                account.updateLanguage();
                                account.updateIterate();
                            },
                        );
                        BeamsService.check(userSid);
                        let farmManagementsResponse =
                            ManagementMembershipService.list(
                                {
                                    user__sid: userSid,
                                    active: true,
                                },
                                {
                                    expand: ["permissions"],
                                },
                            );
                        let growerManagementsResponse =
                            GrowerManagementMembershipService.list(
                                {
                                    user__sid: userSid,
                                    active: true,
                                },
                                {
                                    expand: ["permissions"],
                                },
                            );

                        let growerMembershipsResponse =
                            GrowerMembershipService.list({
                                user__sid: userSid,
                            });

                        return $q
                            .all([
                                farmManagementsResponse,
                                growerManagementsResponse,
                                growerMembershipsResponse,
                            ])
                            .then(function (responses) {
                                return AccountService.updatePermissions(
                                    ...responses,
                                );
                            });
                    },
                    function () {
                        addBreadcrumb({
                            category: "auth",
                            message: "Could not get user sid from token",
                        });
                        AuthService.logout();
                        captureMessage(
                            "Logging out due to unable to get user sid from token",
                        );
                        BeamsService.stop();
                    },
                );
            },
            function (reason) {
                addBreadcrumb({
                    category: "auth",
                    message: reason,
                });
                AuthService.logout();
                BeamsService.stop();
            },
        )
        .finally(function () {
            $urlRouter.sync();
            $urlRouter.listen();
        });

    $transitions.onEnter({}, function (transition) {
        const toState = transition.to();
        if (toState.external) {
            transition
                .injector()
                .getAsync("redirect")
                .then(function (redirect) {
                    $window.open(redirect, "_self");
                });
            return false;
        } else {
            addBreadcrumb({
                category: "navigation",
                message: "Transition started to " + toState.name,
            });
        }
        return true;
    });

    $transitions.onFinish({}, function (transition) {
        $rootScope.previousTransition = transition;
    });

    $transitions.onSuccess({}, function (transition) {
        let state = transition.to();
        addBreadcrumb({
            category: "navigation",
            message: "Transition finshed to " + state.name,
        });
        let mixpanelEvent = _.get(state, "data.mixpanelEvent");
        if (mixpanelEvent) {
            let mixpanelEventProperties = _.get(
                state,
                "data.mixpanelEventProperties",
                {},
            );
            let resolvedMixpanelProperties = {};
            _.forEach(mixpanelEventProperties, function (resolvePath, name) {
                let [obj, path] = resolvePath.split(".", 2);
                let resolvedObject = transition.injector().get(obj);
                resolvedMixpanelProperties[name] = _.get(resolvedObject, path);
            });
            mixpanel.track(mixpanelEvent, resolvedMixpanelProperties);
        }

        let elementID = transition.params().scrollTo;
        if (elementID) {
            $location.hash(elementID);
            $anchorScroll(elementID);
        }

        $rootScope.showSidebar = false;
    });

    $transitions.onError({}, function (transition) {
        let error = transition.error();
        let error_type = error.type;
        if (
            error_type === RejectType.INVALID ||
            error_type === RejectType.ERROR
        ) {
            let detail = error.detail;
            let redirectTo = detail.redirectTo;
            if (redirectTo) {
                $state.go(redirectTo.state, redirectTo.params);
            } else if (detail.status) {
                let status = detail.status;
                if (
                    status === BAD_REQUEST ||
                    status === FORBIDDEN ||
                    status === NOT_FOUND ||
                    status === UNPROCESSABLE_ENTITY
                ) {
                    $state.go(homescreenState);
                    captureException("Transition Error: " + status, {
                        contexts: {
                            error: {
                                from_state: transition.from().name,
                                to_state: transition.to().name,
                                error_type: error_type,
                                status: status,
                                data: JSON.stringify(detail.data),
                            },
                        },
                    });
                } else {
                    captureException(
                        "Transition Error: " +
                            _.get(error, "detail.message", "Unknown"),
                        {
                            contexts: {
                                transition: error,
                            },
                        },
                    );
                }
            } else if (error.detail.name === "ChunkLoadError") {
                let toUrl = $state.href(transition.to(), transition.params());
                $window.open(toUrl, "_self");
            } else {
                captureException("Transition error final", {
                    contexts: {
                        error: {
                            detail: JSON.stringify(detail),
                        },
                    },
                });
            }
        }
    });

    $window.addEventListener("storage", (event) => {
        if (event.key === "ls.refresh") {
            if (event.newValue !== null) {
                let oldUserSid = AuthService.getAuthenticatedUserSid(
                    event.oldValue,
                );
                let newUserSid = AuthService.getAuthenticatedUserSid(
                    event.newValue,
                );
                $q.all([oldUserSid, newUserSid]).then(function ([
                    oldSid,
                    newSid,
                ]) {
                    if (oldSid !== newSid) {
                        addBreadcrumb({
                            category: "auth",
                            message: "User was changed in another tab",
                            data: { oldSid, newSid },
                        });
                        $state.go(homescreenState, {}, { reload: true });
                    }
                });
            } else {
                AuthService.logout();
            }
        }
    });

    $rootScope.$on(
        "LocalStorageModule.notification.setitem",
        function (event, opts) {
            addBreadcrumb({
                category: "auth",
                message: "Localstorage set",
                data: opts,
            });
        },
    );
    $rootScope.$on(
        "LocalStorageModule.notification.removeitem",
        function (event, opts) {
            addBreadcrumb({
                category: "auth",
                message: "Localstorage remove",
                data: opts,
            });
        },
    );
}
