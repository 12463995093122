import template from "./places_autocomplete.html";
import { g_maps_loader } from "../../../common/common.utils";

class PlacesAutocompleteController {
    /* @ngInject */
    constructor($document) {
        this.$document = $document;
    }

    $onInit() {
        let ctrl = this;
        g_maps_loader.importLibrary("places").then(function (places) {
            let pacInputElement = ctrl.$document[0].getElementById("pac");
            const options = {
                strictBounds: false,
                types: ctrl.lookups,
            };
            const autocomplete = new places.Autocomplete(
                pacInputElement,
                options,
            );
            autocomplete.addListener("place_changed", function () {
                let place = autocomplete.getPlace();
                ctrl.onPlaceUpdate({ place });
                if (ctrl.lookups === ["address"]) {
                    ctrl.readableLocation = place.formatted_address;
                } else if (ctrl.lookups === ["establishment"]) {
                    ctrl.readableLocation = place.name;
                }
            });
        });
    }
}
export default {
    bindings: {
        onPlaceUpdate: "&",
        lookups: "<",
        placeholder: "@",
        readableLocation: "@",
    },
    controller: PlacesAutocompleteController,
    template: template,
    name: "placesAutocomplete",
};
