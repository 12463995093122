import template from "./map.html";
import { g_maps_loader } from "../../../common/common.utils";
import _ from "lodash";

class MapController {
    /* @ngInject */
    constructor($document, $scope, $q) {
        this.$document = $document;
        this.$scope = $scope;
        this.$q = $q;
    }

    $onInit() {
        let ctrl = this;
        let mapsLibrary = g_maps_loader.importLibrary("maps");
        let markerLibrary = g_maps_loader.importLibrary("marker");
        let coreLibrary = g_maps_loader.importLibrary("core");
        ctrl.$q
            .all([mapsLibrary, markerLibrary, coreLibrary])
            .then(function (libs) {
                let [maps, marker, core] = libs;
                const map = new maps.Map(
                    ctrl.$document[0].getElementById(
                        "announcement-location-map",
                    ),
                    {
                        zoom: 15,
                        mapTypeId: "hybrid",
                        fullscreenControl: false,
                        streetViewControl: false,
                        controlSize: 20,
                    },
                );

                function updateMarker(marker, position) {
                    console.log(marker, position);
                    marker.setPosition(position);
                    map.panTo(marker.getPosition());
                    ctrl.onMarkerUpdate({ position: marker.getPosition() });
                    marker.addListener("dragend", function () {
                        ctrl.onMarkerUpdate({ position: marker.getPosition() });
                        map.panTo(marker.getPosition());
                    });
                }

                let centerPosition;
                if (_.isNil(ctrl.markerLat) && _.isNil(ctrl.markerLng)) {
                    centerPosition = {
                        lat: ctrl.centerLat,
                        lng: ctrl.centerLng,
                    };
                    let clickListener = map.addListener("click", function (e) {
                        if (_.isNil(ctrl.marker)) {
                            core.event.removeListener(clickListener);
                            ctrl.marker = new marker.Marker({
                                map: map,
                                draggable: true,
                            });
                            ctrl.onMarkerUpdate({ position: e.latLng });
                        }
                    });
                } else {
                    centerPosition = {
                        lat: ctrl.markerLat,
                        lng: ctrl.markerLng,
                    };
                    ctrl.marker = new marker.Marker({
                        map: map,
                        draggable: !ctrl.static,
                    });
                    updateMarker(ctrl.marker, centerPosition);
                }
                map.setCenter(centerPosition);

                if (!ctrl.static) {
                    ctrl.$scope.$watch("$ctrl.markerLat", function () {
                        if (
                            !_.isNil(ctrl.markerLat) &&
                            !_.isNil(ctrl.markerLng)
                        ) {
                            let position = {
                                lat: ctrl.markerLat,
                                lng: ctrl.markerLng,
                            };
                            console.log("firing here");
                            if (_.isNil(ctrl.marker)) {
                                ctrl.marker = new marker.Marker({
                                    map: map,
                                    draggable: true,
                                });
                            }
                            updateMarker(ctrl.marker, position);
                        }
                    });
                }
            });
    }
}
export default {
    bindings: {
        markerLat: "=",
        markerLng: "=",
        centerLat: "<",
        centerLng: "<",
        static: "<",
        onMarkerUpdate: "&",
    },
    controller: MapController,
    template: template,
    name: "dynamicMap",
};
