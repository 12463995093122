import template from "./info_block.html";

export default {
    bindings: {
        label: "@",
        content: "@",
    },
    template: template,
    name: "infoBlock",
};
