import StringMask from "string-mask";
import maskFactory from "angular-input-masks/src/helpers/mask-factory";
import dateInputTemplate from "./date_input.html";
import zipcodeInputTemplate from "./zip_code_input.html";
import socialInputTemplate from "./social_input.html";
import emailInputTemplate from "./email_input.html";
import phoneInputTemplate from "./phone_input.html";
import formTextInputTemplate from "./form_text_input.html";
import stateInputTemplate from "./state_input.html";
import countryInputTemplate from "./country_input.html";
import formSelectInputTemplate from "./form_select_input.html";
import middleInitialInputTemplate from "./middle_initial_input.html";
import i94InputTemplate from "./i94_input_template.html";
import alienNumberInputTemplate from "./alien_number_input.html";
import { addBreadcrumb, captureMessage } from "@sentry/browser";

// export const uiUsSocialSecurityMask = maskFactory({
//     clearValue: function (rawValue) {
//         console.log("clearValueing...")
//         console.log(rawValue);
//         console.log(rawValue.toString().replace(/[^0-9]/g, ""))
//         return rawValue.toString().replace(/[^0-9]/g, "");
//     },
//     format: function (cleanValue) {
//         console.log("formatting")
//         console.log(cleanValue);
//         var formattedValue =
//             new StringMask("000-00-0000").apply(cleanValue) || "";
//         console.log(formattedValue)
//         console.log(formattedValue.trim().replace(/[^0-9]$/, ""))
//         return formattedValue.trim().replace(/[^0-9]$/, "");
//     },
//     getModelValue: function (formattedValue, originalModelType) {
//         console.log("in getModelValue")
//         console.log(formattedValue);
//         return formattedValue;
//     },
//     validations: {
//         errors: function (value) {
//             return value && value.toString().length === 11;
//         },
//     },
// });

/* @ngInject */
export function uiUsSocialSecurityMask($parse) {
    return {
        restrict: "A",
        require: "ngModel",
        link: {
            pre: function (scope, element, attrs, ctrl) {
                let ssnMask = new StringMask("000-00-0000");

                function formatter(value) {
                    if (ctrl.$isEmpty(value)) {
                        return "";
                    }
                    let cleanValue = value.replace(/[^0-9]/g, "");
                    let formattedValue = ssnMask.apply(cleanValue) || "";
                    return formattedValue.trim().replace(/[^0-9]$/, "");
                }

                ctrl.$formatters.push(function (value) {
                    return formatter(value);
                });

                ctrl.$parsers.push(function parser(value) {
                    if (ctrl.$isEmpty(value)) {
                        return value;
                    }

                    let formattedValue = formatter(value);

                    if (ctrl.$viewValue !== formattedValue) {
                        ctrl.$setViewValue(formattedValue);
                        ctrl.$render();
                    }

                    return formattedValue;
                });

                ctrl.$validators.length = function (modelValue, viewValue) {
                    if (ctrl.$isEmpty(modelValue)) {
                        return true;
                    }
                    return modelValue.length === 11;
                };
            },
            post: function (scope, element, attrs, ctrl) {
                // This is to initialize by formatting the original value
                let ssnMask = new StringMask("000-00-0000");

                function formatter(value) {
                    if (ctrl.$isEmpty(value)) {
                        return "";
                    }
                    let cleanValue = value.replace(/[^0-9]/g, "");
                    let formattedValue = ssnMask.apply(cleanValue) || "";
                    return formattedValue.trim().replace(/[^0-9]$/, "");
                }

                let originalValue = $parse(attrs.ngModel)(scope);
                let formattedValue = formatter(originalValue);
                if (ctrl.$modelValue !== formattedValue) {
                    ctrl.$setViewValue(formattedValue);
                    ctrl.$render();
                }
            },
        },
    };
}

export const uiUsZipCodeMask = maskFactory({
    clearValue: function (rawValue) {
        return rawValue.toString().replace(/[^0-9]/g, "");
    },
    format: function (cleanValue) {
        var formattedValue = new StringMask("00000").apply(cleanValue) || "";
        return formattedValue.trim().replace(/[^0-9]$/, "");
    },
    getModelValue: function (formattedValue, originalModelType) {
        return formattedValue;
    },
    validations: {
        errors: function (value) {
            return (
                value &&
                (value.toString().length === 5 ||
                    value.toString().length === 10)
            );
        },
    },
});

import { format as formatDate } from "date-fns";
import moment from "moment";

function isISODateString(date) {
    return /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}([-+][0-9]{2}:[0-9]{2}|Z)$/.test(
        date.toString(),
    );
}

/* @ngInject */
export function paperworkDateMask() {
    return {
        restrict: "A",
        require: "ngModel",
        link: function (scope, element, attrs, ctrl) {
            attrs.parse = attrs.parse || "true";

            let dateFormat = attrs.paperworkDateMask;

            let checkExpiration = scope.checkExpiration || false;

            var dateMask = new StringMask(dateFormat.replace(/[YMD]/g, "0"));

            function formatter(value) {
                if (ctrl.$isEmpty(value)) {
                    return null;
                }

                let cleanValue = value;

                if (moment.isMoment(value)) {
                    cleanValue = value.format(dateFormat);
                } else if (isISODateString(value)) {
                    cleanValue = formatDate(value, dateFormat);
                }

                cleanValue = cleanValue.replace(/[^0-9]/g, "");
                let formattedValue = dateMask.apply(cleanValue) || "";
                return formattedValue.trim().replace(/[^0-9]$/, "");
            }

            ctrl.$formatters.push(formatter);

            ctrl.$parsers.push(function parser(value) {
                if (ctrl.$isEmpty(value)) {
                    return value;
                }

                let formattedValue = formatter(value);

                if (ctrl.$viewValue !== formattedValue) {
                    ctrl.$setViewValue(formattedValue);
                    ctrl.$render();
                }

                return attrs.parse === "false"
                    ? formattedValue
                    : moment(value, dateFormat);
            });

            ctrl.$validators.date = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }

                return (
                    modelValue.isValid() &&
                    viewValue.length === dateFormat.length
                );
            };
            if (checkExpiration) {
                ctrl.$validators.expired = function (modelValue, viewValue) {
                    return !moment().isAfter(modelValue);
                };
            }
        },
    };
}

export const middleInitialMask = maskFactory({
    clearValue: function (rawValue) {
        return rawValue.toString();
    },
    format: function (cleanValue) {
        var formattedValue = new StringMask("U").apply(cleanValue) || "";
        return formattedValue;
    },
    getModelValue: function (formattedValue, originalModelType) {
        return formattedValue;
    },
    validations: {
        errors: function (value) {
            return value && value.toString().length === 1;
        },
    },
});

export const i94Mask = maskFactory({
    clearValue: function (rawValue) {
        return rawValue.toString().replace(/[^0-9]/g, "");
    },
    format: function (cleanValue) {
        var formattedValue =
            new StringMask("00000000000").apply(cleanValue) || "";
        return formattedValue.trim().replace(/[^0-9]$/, "");
    },
    getModelValue: function (formattedValue, originalModelType) {
        return formattedValue;
    },
    validations: {
        errors: function (value) {
            return value && value.toString().length === 11;
        },
    },
});

export const alienNumberMask = maskFactory({
    clearValue: function (rawValue) {
        return rawValue.toUpperCase();
    },
    format: function (cleanValue) {
        let mask;
        if (cleanValue.startsWith("A")) {
            mask = new StringMask("U000000099");
        } else {
            mask = new StringMask("000000099");
        }
        return mask.apply(cleanValue) || "";
    },
    getModelValue: function (formattedValue, originalModelType) {
        return formattedValue;
    },
    validations: {
        errors: function (value) {
            return value && value.toString().length >= 7;
        },
    },
});

/* @ngInject */
export function formTextInput() {
    return {
        restrict: "E",
        template: formTextInputTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputDisabled: "=ngDisabled",
            inputRequired: "=ngRequired",
            inputName: "@",
            inputLabel: "@",
            inputErrors: "=",
            form: "=",
            inputMinlength: "=ngMinlength",
            inputMaxlength: "=ngMaxlength",
        },
    };
}

/* @ngInject */
export function dateInput() {
    return {
        restrict: "E",
        template: dateInputTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputDisabled: "=ngDisabled",
            inputRequired: "=ngRequired",
            inputName: "@",
            inputLabel: "@",
            inputErrors: "=",
            mask: "@",
            form: "=",
            checkExpiration: "=",
        },
    };
}

/* @ngInject */
export function zipCodeInput() {
    return {
        restrict: "E",
        template: zipcodeInputTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputDisabled: "=ngDisabled",
            inputRequired: "=ngRequired",
            inputName: "@",
            inputLabel: "@",
            inputErrors: "=",
            form: "=",
        },
    };
}

/* @ngInject */
export function socialInput() {
    return {
        restrict: "E",
        template: socialInputTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputDisabled: "=ngDisabled",
            inputRequired: "=ngRequired",
            inputName: "@",
            inputLabel: "@",
            inputErrors: "=",
            form: "=",
        },
    };
}

/* @ngInject */
export function emailInput() {
    return {
        restrict: "E",
        template: emailInputTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputDisabled: "=ngDisabled",
            inputRequired: "=ngRequired",
            inputName: "@",
            inputLabel: "@",
            inputErrors: "=",
            form: "=",
        },
    };
}

/* @ngInject */
export function phoneInput() {
    return {
        restrict: "E",
        template: phoneInputTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputDisabled: "=ngDisabled",
            inputRequired: "=ngRequired",
            inputName: "@",
            inputLabel: "@",
            inputErrors: "=",
            form: "=",
        },
    };
}

/* @ngInject */
export function stateInput() {
    return {
        restrict: "E",
        template: stateInputTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputDisabled: "=ngDisabled",
            inputRequired: "=ngRequired",
            inputName: "@",
            inputLabel: "@",
            inputErrors: "=",
            form: "=",
        },
        link: function (scope, elem, attrs) {
            scope.states = [
                "AK",
                "AL",
                "AR",
                "AZ",
                "CA",
                "CO",
                "CT",
                "DC",
                "DE",
                "FL",
                "GA",
                "HI",
                "IA",
                "ID",
                "IL",
                "IN",
                "KS",
                "KY",
                "LA",
                "MA",
                "MD",
                "ME",
                "MI",
                "MN",
                "MO",
                "MS",
                "MT",
                "NC",
                "ND",
                "NE",
                "NH",
                "NJ",
                "NM",
                "NV",
                "NY",
                "OH",
                "OK",
                "OR",
                "PA",
                "RI",
                "SC",
                "SD",
                "TN",
                "TX",
                "UT",
                "VA",
                "VT",
                "WA",
                "WI",
                "WV",
                "WY",
            ];
        },
    };
}

/* @ngInject */
export function countryInput() {
    return {
        restrict: "E",
        template: countryInputTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputDisabled: "=ngDisabled",
            inputRequired: "=ngRequired",
            inputName: "@",
            inputLabel: "@",
            inputErrors: "=",
            form: "=",
        },
        link: function (scope, elem, attrs) {
            scope.countries = [
                "",
                "Afghanistan",
                "Åland Islands",
                "Albania",
                "Algeria",
                "American Samoa",
                "Andorra",
                "Angola",
                "Anguilla",
                "Antarctica",
                "Antigua and Barbuda",
                "Argentina",
                "Armenia",
                "Aruba",
                "Australia",
                "Austria",
                "Azerbaijan",
                "Bahamas",
                "Bahrain",
                "Bangladesh",
                "Barbados",
                "Belarus",
                "Belgium",
                "Belize",
                "Benin",
                "Bermuda",
                "Bhutan",
                "Bolivia (Plurinational State of)",
                "Bonaire, Sint Eustatius and Saba",
                "Bosnia and Herzegovina",
                "Botswana",
                "Bouvet Island",
                "Brazil",
                "British Indian Ocean Territory",
                "United States Minor Outlying Islands",
                "Virgin Islands (British)",
                "Virgin Islands (U.S.)",
                "Brunei Darussalam",
                "Bulgaria",
                "Burkina Faso",
                "Burundi",
                "Cambodia",
                "Cameroon",
                "Canada",
                "Cabo Verde",
                "Cayman Islands",
                "Central African Republic",
                "Chad",
                "Chile",
                "China",
                "Christmas Island",
                "Cocos (Keeling) Islands",
                "Colombia",
                "Comoros",
                "Congo",
                "Congo (Democratic Republic of the)",
                "Cook Islands",
                "Costa Rica",
                "Croatia",
                "Cuba",
                "Curaçao",
                "Cyprus",
                "Czech Republic",
                "Denmark",
                "Djibouti",
                "Dominica",
                "Dominican Republic",
                "Ecuador",
                "Egypt",
                "El Salvador",
                "Equatorial Guinea",
                "Eritrea",
                "Estonia",
                "Ethiopia",
                "Falkland Islands (Malvinas)",
                "Faroe Islands",
                "Fiji",
                "Finland",
                "France",
                "French Guiana",
                "French Polynesia",
                "French Southern Territories",
                "Gabon",
                "Gambia",
                "Georgia",
                "Germany",
                "Ghana",
                "Gibraltar",
                "Greece",
                "Greenland",
                "Grenada",
                "Guadeloupe",
                "Guam",
                "Guatemala",
                "Guernsey",
                "Guinea",
                "Guinea-Bissau",
                "Guyana",
                "Haiti",
                "Heard Island and McDonald Islands",
                "Holy See",
                "Honduras",
                "Hong Kong",
                "Hungary",
                "Iceland",
                "India",
                "Indonesia",
                "Côte d'Ivoire",
                "Iran (Islamic Republic of)",
                "Iraq",
                "Ireland",
                "Isle of Man",
                "Israel",
                "Italy",
                "Jamaica",
                "Japan",
                "Jersey",
                "Jordan",
                "Kazakhstan",
                "Kenya",
                "Kiribati",
                "Kuwait",
                "Kyrgyzstan",
                "Lao People's Democratic Republic",
                "Latvia",
                "Lebanon",
                "Lesotho",
                "Liberia",
                "Libya",
                "Liechtenstein",
                "Lithuania",
                "Luxembourg",
                "Macao",
                "Macedonia (the former Yugoslav Republic of)",
                "Madagascar",
                "Malawi",
                "Malaysia",
                "Maldives",
                "Mali",
                "Malta",
                "Marshall Islands",
                "Martinique",
                "Mauritania",
                "Mauritius",
                "Mayotte",
                "Mexico",
                "Micronesia (Federated States of)",
                "Moldova (Republic of)",
                "Monaco",
                "Mongolia",
                "Montenegro",
                "Montserrat",
                "Morocco",
                "Mozambique",
                "Myanmar",
                "Namibia",
                "Nauru",
                "Nepal",
                "Netherlands",
                "New Caledonia",
                "New Zealand",
                "Nicaragua",
                "Niger",
                "Nigeria",
                "Niue",
                "Norfolk Island",
                "Korea (Democratic People's Republic of)",
                "Northern Mariana Islands",
                "Norway",
                "Oman",
                "Pakistan",
                "Palau",
                "Palestine, State of",
                "Panama",
                "Papua New Guinea",
                "Paraguay",
                "Peru",
                "Philippines",
                "Pitcairn",
                "Poland",
                "Portugal",
                "Puerto Rico",
                "Qatar",
                "Republic of Kosovo",
                "Réunion",
                "Romania",
                "Russian Federation",
                "Rwanda",
                "Saint Barthélemy",
                "Saint Helena, Ascension and Tristan da Cunha",
                "Saint Kitts and Nevis",
                "Saint Lucia",
                "Saint Martin (French part)",
                "Saint Pierre and Miquelon",
                "Saint Vincent and the Grenadines",
                "Samoa",
                "San Marino",
                "Sao Tome and Principe",
                "Saudi Arabia",
                "Senegal",
                "Serbia",
                "Seychelles",
                "Sierra Leone",
                "Singapore",
                "Sint Maarten (Dutch part)",
                "Slovakia",
                "Slovenia",
                "Solomon Islands",
                "Somalia",
                "South Africa",
                "South Georgia and the South Sandwich Islands",
                "Korea (Republic of)",
                "South Sudan",
                "Spain",
                "Sri Lanka",
                "Sudan",
                "Suriname",
                "Svalbard and Jan Mayen",
                "Swaziland",
                "Sweden",
                "Switzerland",
                "Syrian Arab Republic",
                "Taiwan",
                "Tajikistan",
                "Tanzania, United Republic of",
                "Thailand",
                "Timor-Leste",
                "Togo",
                "Tokelau",
                "Tonga",
                "Trinidad and Tobago",
                "Tunisia",
                "Turkey",
                "Turkmenistan",
                "Turks and Caicos Islands",
                "Tuvalu",
                "Uganda",
                "Ukraine",
                "United Arab Emirates",
                "United Kingdom of Great Britain and Northern Ireland",
                "United States of America",
                "Uruguay",
                "Uzbekistan",
                "Vanuatu",
                "Venezuela (Bolivarian Republic of)",
                "Viet Nam",
                "Wallis and Futuna",
                "Western Sahara",
                "Yemen",
                "Zambia",
                "Zimbabwe",
            ];
        },
    };
}

/* @ngInject */
export function formSelectInput() {
    return {
        restrict: "E",
        template: formSelectInputTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputDisabled: "=ngDisabled",
            inputRequired: "=ngRequired",
            inputName: "@",
            inputLabel: "@",
            inputErrors: "=",
            form: "=",
            inputOptions: "=",
        },
    };
}

/* @ngInject */
export function middleInitialInput() {
    return {
        restrict: "E",
        template: middleInitialInputTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputDisabled: "=ngDisabled",
            inputRequired: "=ngRequired",
            inputName: "@",
            inputLabel: "@",
            form: "=",
        },
    };
}

/* @ngInject */
export function i94Input() {
    return {
        restrict: "E",
        template: i94InputTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputDisabled: "=ngDisabled",
            inputRequired: "=ngRequired",
            inputName: "@",
            inputLabel: "@",
            form: "=",
        },
    };
}

/* @ngInject */
export function alienNumberInput() {
    return {
        restrict: "E",
        template: alienNumberInputTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputDisabled: "=ngDisabled",
            inputRequired: "=ngRequired",
            inputName: "@",
            inputLabel: "@",
            form: "=",
        },
    };
}

/* @ngInject */
export function observeMe() {
    return {
        restrict: "A",
        scope: {
            observer: "=",
        },
        link: function ($scope, elem, attrs) {
            $scope.observer.observe(elem[0]);
            $scope.$on("$destroy", function () {
                // This seems to be a noop in some cases (new preview render)
                $scope.observer.unobserve(elem[0]);
            });
        },
    };
}
