"use strict";

import changeLanguageTemplate from "./change_language.html";
import sortTemplate from "./sort.html";
import filterTemplate from "./filter.html";
import selectedSubjectsListTemplate from "./selected_subjects_list.html";
import employeeInfoTemplate from "./employee_info.html";
import inSortTemplate from "./inSort.html";
import formActionTemplate from "./form_action.html";
import { ENGLISH, SPANISH } from "../../app.constants";
import mixpanel from "mixpanel-browser";
import _ from "lodash";
import { captureMessage } from "@sentry/browser";

export const DATE = "date";
export const CHECK = "checkbox";
export const SELECT = "select";
export const CREW_SELECT = "crew_select";
export const USER_SELECT = "user_select";

class ChangeLanguageController {
    /* @ngInject */
    constructor($translate, $state, amMoment) {
        this.$translate = $translate;
        this.$state = $state;
        this.ENGLISH = ENGLISH;
        this.SPANISH = SPANISH;
        this.currentLanguage = $translate.proposedLanguage();
        this.amMoment = amMoment;
    }

    changeLanguage(langKey) {
        let ctrl = this;
        ctrl.$translate.use(langKey).then(function (value) {
            ctrl.currentLanguage = value;
            mixpanel.track("Changed language", { language: value });
            if (ctrl.account) {
                ctrl.account.preferred_language = value;
                ctrl.account
                    .save()
                    .catch(() => {})
                    .then(function () {
                        ctrl.$state.reload();
                        ctrl.amMoment.changeLocale(value + "-us");
                    });
            }
        });
    }
}

export const ChangeLanguageComponent = {
    controller: ChangeLanguageController,
    bindings: { account: "<" },
    template: changeLanguageTemplate,
};

export const FormActionComponent = {
    bindings: {
        nonFieldErrors: "<",
        submitLabel: "<",
        cancelLabel: "<",
        hideCancel: "<",
        showSpinner: "<",
        cancelSref: "<",
        disable: "<",
        submitButtonColor: "<",
    },
    template: formActionTemplate,
};

export const SelectedSubjectsListComponent = {
    bindings: {
        subjects: "<",
    },
    template: selectedSubjectsListTemplate,
};

class EmployeeInfoController {
    /* @ngInject */
    constructor() {
        this.ERROR = "error";
    }
}
export const EmployeeInfoComponent = {
    controller: EmployeeInfoController,
    bindings: {
        user: "<",
        profile: "<",
        growerMembership: "<",
        linkToProfile: "<",
        active: "<",
        showProfilePicture: "<",
        dateAdded: "<",
        showCommStatus: "<",
        mini: "<",
    },
    template: employeeInfoTemplate,
};

class BaseSortController {
    /* @ngInject */
    constructor($document, $element, $timeout) {
        this.$document = $document;
        this.$element = $element;
        this.$timeout = $timeout;
        this.expanded = false;
        let ctrl = this;
        ctrl.$document.on("click", function (event) {
            if (ctrl.expanded) {
                ctrl.$timeout(function () {
                    let contents = ctrl.$element.find("*");
                    ctrl.expanded = Array.from(contents).includes(event.target);
                });
            }
        });
    }
}
export const sortComponent = {
    controller: BaseSortController,
    bindings: {
        sorts: "<",
        sortRules: "<",
    },
    template: sortTemplate,
};
export const inSortComponent = {
    controller: BaseSortController,
    bindings: {
        sorts: "<",
        callback: "&",
    },
    template: inSortTemplate,
};

class FilterController {
    /* @ngInject */
    constructor($state, $document, $element, $timeout) {
        this.$state = $state;
        this.$document = $document;
        this.$element = $element;
        this.$timeout = $timeout;
        let ctrl = this;
        ctrl.DATE = DATE;
        ctrl.CHECK = CHECK;
        ctrl.SELECT = SELECT;
        ctrl.CREW_SELECT = CREW_SELECT;
        ctrl.USER_SELECT = USER_SELECT;
        ctrl.showFilters = false;
        ctrl.$document.on("click", function (event) {
            if (ctrl.showFilters) {
                ctrl.$timeout(function () {
                    let contents = ctrl.$element.find("*");
                    ctrl.showFilters = Array.from(contents).includes(
                        event.target,
                    );
                });
            }
        });
    }

    $onInit() {
        let ctrl = this;
        _.forEach(ctrl.filters, function (item) {
            item.out = ctrl.$state.params[item.name];
        });
    }

    filterAction(filters) {
        let ctrl = this;
        ctrl.callback({ filter: filters });
        ctrl.showFilters = false;
    }

    applyFilters(filters) {
        let ctrl = this;
        ctrl.filterAction(filters);
    }

    clearFilters(filters) {
        let ctrl = this;
        let clearedFilters = _.map(filters, function (filter) {
            filter.out = undefined;
            return filter;
        });
        ctrl.filterAction(clearedFilters);
    }
}

export const FilterComponent = {
    controller: FilterController,
    bindings: {
        filters: "<",
        callback: "&",
    },
    template: filterTemplate,
};

function getBase64SizeInBytes(base64String) {
    const byteCharacters = atob(base64String);
    const byteCount = byteCharacters.length;
    return byteCount;
}

import signatureTemplate from "./signature.html";
import SignaturePad from "signature_pad";
class SignatureController {
    /* @ngInject */
    constructor($window, $scope, $element) {
        this.$window = $window;
        this.$scope = $scope;
        this.SIGNIFICANT_SIZE = 12000;
        this.$element = $element;
    }

    clear() {
        let ctrl = this;
        ctrl.signaturePad.clear();
        let value = ctrl.signaturePad.toDataURL();
        if (ctrl.dsp) {
            value = value.split(",")[1];
        }
        ctrl.onUpdate({ value: value, isEmpty: ctrl.signaturePad.isEmpty() });
    }

    $onInit() {
        let ctrl = this;
        let canvas = ctrl.$element.find("canvas")[0];
        ctrl.signaturePad = new SignaturePad(canvas);
        ctrl.signaturePad.addEventListener("beginStroke", function () {
            if (!ctrl.signaturePad.isEmpty()) {
                let value = ctrl.signaturePad.toDataURL();
                if (ctrl.dsp) {
                    value = value.split(",")[1];
                }
                ctrl.onStart({ value: value });
            }
        });
        ctrl.signaturePad.addEventListener(
            "endStroke",
            _.debounce(function () {
                if (!ctrl.signaturePad.isEmpty()) {
                    let value = ctrl.signaturePad.toDataURL();
                    if (ctrl.dsp) {
                        value = value.split(",")[1];
                    }
                    ctrl.onUpdate({
                        value: value,
                        isEmpty: ctrl.signaturePad.isEmpty(),
                    });
                }
            }, 500),
        );

        let resizeObserverOn = false;
        function resizeCanvas(event) {
            let ratio = Math.max(ctrl.$window.devicePixelRatio || 1, 1);
            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = canvas.offsetHeight * ratio;
            canvas.getContext("2d").scale(ratio, ratio);
            ctrl.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
            if (!resizeObserverOn) {
                new ResizeObserver(resizeCanvas).observe(canvas);
                resizeObserverOn = true;
            }
            ctrl.$scope.$applyAsync();
        }
        resizeCanvas();
        if (ctrl.initialSignature) {
            const img = new Image();
            let base64 = "data:image/png;base64," + ctrl.initialSignature;
            img.onload = () => {
                const hRatio = canvas.offsetWidth / img.width;
                const vRatio = canvas.offsetHeight / img.height;
                const ratio = Math.min(hRatio, vRatio, 1);
                const imgWidth = img.width * ratio;
                const imgHeight = img.height * ratio;
                const xOffset = (canvas.offsetWidth - imgWidth) / 2;
                const yOffset = (canvas.offsetHeight - imgHeight) / 2;
                // Preserve aspect ratio when loading signature
                // TODO: fix offsets. for some reason they're not being respected and
                //  are treated as 0, 0
                ctrl.signaturePad.fromDataURL(base64, {
                    width: imgWidth,
                    height: imgHeight,
                    xOffset,
                    yOffset,
                });
                ctrl.$scope.$applyAsync();
            };
            img.src = base64;
        }
    }
}

export const SignatureComponent = {
    controller: SignatureController,
    bindings: { onUpdate: "&", onStart: "&", initialSignature: "<", dsp: "<" },
    template: signatureTemplate,
    name: "signature",
};

import translatedSnippetTemplate from "./translated_snippet.html";
export const TranslatedSnippetComponent = {
    bindings: {
        display: "<",
        displayLanguage: "<",
        original: "<",
        originalLanguage: "<",
    },
    template: translatedSnippetTemplate,
    name: "translatedSnippet",
};

class BreadrumbController {
    /* @ngInject */
    constructor($state, $translate, $rootScope, $scope) {
        this.$state = $state;
        this.$translate = $translate;
        this.breadcrumbs = [];
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.hideBreadcrumb = false;
    }
    $onInit() {
        let ctrl = this;
        let listener = function (event, toState) {
            let path = ctrl.$state.getCurrentPath();
            let breadcrumbChain = [];
            _.forEach(path, function (state) {
                state = state.state;
                if (state.data && state.data.hasOwnProperty("breadcrumb")) {
                    let resolvedVariables = {};
                    _.forEach(_.keys(state.resolve), function (name) {
                        resolvedVariables[name] = event.injector().get(name);
                    });
                    breadcrumbChain.push({
                        label: ctrl.$translate.instant(
                            state.data.breadcrumb,
                            resolvedVariables,
                        ),
                        link: state.name,
                    });
                }
            });
            ctrl.breadcrumbs = breadcrumbChain;
            ctrl.hideBreadcrumb =
                ctrl.breadcrumbs.length === 1 &&
                toState.data &&
                toState.data.hasOwnProperty("breadcrumb");
        };

        ctrl.$scope.$watch("$root.previousTransition", function () {
            let previousTransition = ctrl.$rootScope.previousTransition;
            listener(previousTransition, previousTransition.to());
        });
    }
}

import breadcrumbTemplate from "./breadcrumb.html";
export const BreadcrumbComponent = {
    controller: BreadrumbController,
    template: breadcrumbTemplate,
    name: "breadcrumb",
};
