import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { saveAs } from "file-saver";
import { UNDERSCORE_DATE_FORMAT } from "../paperwork/constants";
import promisePoller from "promise-poller";
import moment from "moment";
import { captureException } from "@sentry/browser";
import _ from "lodash";
import { downloadFile } from "../common/common.utils";

export class GuessGenderService {
    /* @ngInject */
    constructor($http) {
        this.$http = $http;
        this.endpoint = process.env.API_DOMAIN + "/api/";
    }

    guess(first_name) {
        let svc = this;
        return svc.$http.get(svc.endpoint + "guess_gender", {
            params: { first_name: first_name },
        });
    }
}

export class GuessLanguageService {
    /* @ngInject */
    constructor($http) {
        this.$http = $http;
        this.endpoint = process.env.API_DOMAIN + "/api/";
    }

    guess(first_name, last_name) {
        let svc = this;
        return svc.$http.get(svc.endpoint + "guess_language", {
            params: { first_name: first_name, last_name: last_name },
        });
    }
}

export class SvixDashboardService {
    /* @ngInject */
    constructor($http) {
        this.$http = $http;
        this.endpoint = process.env.API_DOMAIN + "/api/";
    }

    getUrl(growerSid) {
        let svc = this;
        return svc.$http
            .get(svc.endpoint + "svix_dashboard", {
                params: { grower_organization: growerSid },
            })
            .then(function (response) {
                return response.data.url;
            });
    }
}

export class StripePortalService {
    /* @ngInject */
    constructor($http) {
        this.$http = $http;
        this.endpoint = process.env.API_DOMAIN + "/api/";
    }

    getUrl(growerSid) {
        let svc = this;
        return svc.$http
            .get(svc.endpoint + "stripe_portal", {
                params: { grower_organization: growerSid },
            })
            .then(function (response) {
                return response.data.url;
            });
    }
}

export class PaperworkSignatureService {
    /* @ngInject */
    constructor($http) {
        this.$http = $http;
        this.endpoint = process.env.API_DOMAIN + "/api/";
    }

    get(signerSid, growerSid) {
        let svc = this;
        return svc.$http
            .get(svc.endpoint + "paperworksignatures", {
                params: { requester: growerSid, signer: signerSid },
            })
            .then(function (response) {
                return response.data.signature;
            });
    }
}

export class ReadabilityService {
    /* @ngInject */
    constructor($http) {
        this.$http = $http;
        this.endpoint = process.env.API_DOMAIN + "/api/";
    }
    check(message_body, language) {
        let svc = this;
        return svc.$http.get(svc.endpoint + "readability", {
            params: { text: message_body, language: language },
        });
    }
}

export class LinkService {
    /* @ngInject */
    constructor($http) {
        this.$http = $http;
        this.endpoint = process.env.API_DOMAIN + "/api/";
    }

    create(path, auth_user_sid) {
        let svc = this;
        return svc.$http.post(svc.endpoint + "links", {
            path,
            auth_user: auth_user_sid,
        });
    }
}

export class ReplySuggestionService {
    /* @ngInject */
    constructor($http) {
        this.$http = $http;
        this.endpoint = process.env.API_DOMAIN + "/api/";
    }

    get(message_history, language) {
        let svc = this;
        return svc.$http.post(svc.endpoint + "reply-suggestion", {
            message_history: message_history,
            target_language: language,
        });
    }
}

export class BeamsService {
    /* @ngInject */
    constructor(ToastService, AuthService) {
        this.AuthService = AuthService;
        this.ToastService = ToastService;
        try {
            this.beamsClient = new PusherPushNotifications.Client({
                instanceId: process.env.BEAMS_INSTANCE_ID,
            });
        } catch {
            this.beamsClient = null;
        }
    }

    check(userSid) {
        let svc = this;
        if (svc.beamsClient) {
            svc.beamsClient.getRegistrationState().then((state) => {
                let states = PusherPushNotifications.RegistrationState;
                switch (state) {
                    case states.PERMISSION_GRANTED_NOT_REGISTERED_WITH_BEAMS: {
                        svc.start(userSid);
                    }
                }
            });
        }
    }

    initializeForConversations(userSid) {
        let svc = this;
        if (svc.beamsClient) {
            svc.beamsClient
                .getRegistrationState()
                .then((state) => {
                    let states = PusherPushNotifications.RegistrationState;
                    switch (state) {
                        case states.PERMISSION_DENIED: {
                            svc.ToastService.create(
                                "beamsService.ENABLE_NOTIFICATIONS_TOAST",
                            );
                        }
                        case states.PERMISSION_PROMPT_REQUIRED: {
                            svc.start(userSid);
                        }
                    }
                })
                .catch((e) =>
                    console.error("Could not get registration state", e),
                );
        }
    }

    start(userSid) {
        let svc = this;
        const tokenProvider = new PusherPushNotifications.TokenProvider({
            url: process.env.API_DOMAIN + "/api/beams",
            headers: {
                Authorization:
                    "Bearer " + svc.AuthService.retrieveAccessToken(),
            },
        });
        if (svc.beamsClient) {
            svc.beamsClient
                .start()
                .then(() => svc.beamsClient.setUserId(userSid, tokenProvider))
                .catch(console.error);
        }
    }

    stop() {
        let svc = this;
        if (svc.beamsClient) {
            svc.beamsClient.stop().catch(console.error);
        }
    }
}

export class ExportEmployeeDataService {
    /* @ngInject */
    constructor(Restangular, $http, ToastService, PollDownloadService) {
        this.resource = "export";
        this.Restangular = Restangular;
        this.$http = $http;
        this.ToastService = ToastService;
        this.PollDownloadService = PollDownloadService;
    }

    companyExportAndSave(format, subjects, asker) {
        let svc = this;
        let params = {
            export_format: format,
            subjects: subjects,
            asker: asker,
        };
        return this.Restangular.all(this.resource)
            .customPOST(params, "company_employees")
            .then(function (response) {
                return svc.PollDownloadService.get(response).then(
                    function (url) {
                        downloadFile(url);
                    },
                    function (reasons) {
                        svc.ToastService.create(
                            "ExportEmployeeDataService.POLL_FAILED",
                        );
                        captureException("Onboarding data export failed", {
                            contexts: {
                                error: {
                                    attempts: reasons.length,
                                    last_attempt: _.last(reasons),
                                },
                            },
                        });
                    },
                );
            });
    }

    exportAndSave(format, subjectSids, asker, farm) {
        let svc = this;
        let params = {
            export_format: format,
            subjects: subjectSids,
            asker: asker,
            farm: farm,
        };
        return this.Restangular.all(this.resource)
            .customPOST(params, "employees")
            .then(function (response) {
                return svc.PollDownloadService.get(response).then(
                    function (url) {
                        let filename = url
                            .split("/")
                            .pop()
                            .split("#")[0]
                            .split("?")[0];
                        saveAs(url, decodeURIComponent(filename));
                    },
                    function (reasons) {
                        svc.ToastService.create(
                            "ExportEmployeeDataService.POLL_FAILED",
                        );
                        captureException("Onboarding data export failed", {
                            contexts: {
                                error: {
                                    attempts: reasons.length,
                                    last_attempt: _.last(reasons),
                                },
                            },
                        });
                    },
                );
            });
    }
}

export class WAFLAContractService {
    /* @ngInject */
    constructor($http) {
        this.$http = $http;
        this.resource = "waflacontracts";
    }

    retrieve(grower_airtable_id) {
        let svc = this;
        return svc.$http
            .get(
                _.join(
                    [
                        process.env.API_DOMAIN,
                        "api",
                        svc.resource,
                        grower_airtable_id,
                    ],
                    "/",
                ),
                { skipAuthorization: true },
            )
            .then((response) => response.data);
    }
}

export class PaperworkQueryService {
    /* @ngInject */
    constructor($http) {
        this.$http = $http;
        this.endpoint = process.env.API_DOMAIN + "/api/";
    }

    query(model, params, grower_sid) {
        let svc = this;
        return svc.$http
            .post(svc.endpoint + "paperworkquery", {
                model,
                params,
                grower_organization: grower_sid,
            })
            .then(function (response) {
                return response.data;
            });
    }
}

export class PollDownloadService {
    /* @ngInject */
    constructor($q) {
        this.$q = $q;
    }

    get(response) {
        let svc = this;
        return svc.$q.when(
            promisePoller({
                retries: 300,
                interval: 1000,
                taskFn: function () {
                    return svc.$q
                        .when(
                            fetch(response.poll_url, {
                                method: "HEAD",
                                redirect: "manual",
                            }),
                        )
                        .then(
                            function (pollResponse) {
                                let deferred = svc.$q.defer();
                                let status = pollResponse.status;
                                if (status > 400) {
                                    deferred.reject(pollResponse);
                                } else if (
                                    status === 0 &&
                                    pollResponse.type === "opaqueredirect"
                                ) {
                                    deferred.resolve(response.download_url);
                                } else {
                                    deferred.resolve(response.download_url);
                                }
                                return deferred.promise;
                            },
                            function (error) {
                                let deferred = svc.$q.defer();
                                deferred.reject(error);
                                return deferred.promise;
                            },
                        );
                },
            }),
        );
    }
}
