import template from "./forbidden.html";
import en from "./en.json";
import es from "./es.json";
import _ from "lodash";
import { homescreenState } from "../../../../app.routes";

class ForbiddenController {
    /* @ngInject */
    constructor() {
        this.homescreenState = homescreenState;
    }
}

export default {
    name: "forbidden",
    template: template,
    bindings: { redirect: "<" },
    translations: { en, es },
    controller: ForbiddenController,
};
