export const I_9_DOCUMENT_PACKAGE_SID = process.env.I_9_DOCUMENT_PACKAGE_SID;
export const I_9_REHIRE_DOCUMENT_PACKAGE_SID =
    process.env.I_9_REHIRE_DOCUMENT_PACKAGE_SID;
export const UNDERSCORE_DATE_FORMAT = "YYYY_MM_DD";
export const DASH_DATE_FORMAT = "YYYY-MM-DD";
export const YES_PREPARER =
    "A preparer(s) and/or translator(s) assisted the employee in completing Section 1.";
export const NO_PREPARER = "I did not use a preparer or translator.";
export const I9_ATTEST_1 = "1. A citizen of the United States";
export const I9_ATTEST_2 = "2. A noncitizen national of the United States";
export const I9_ATTEST_3 = "3. A lawful permanent resident";
export const I9_ATTEST_4 = "4. An alien authorized to work";

export const NOT_STARTED = "not_started";
export const BLOCKED = "blocked";
export const COMPLETE = "completed";
export const PENDING = "pending";
export const SIGNER = "signer";
export const INITIATOR = "initiator";
export const EXTERNAL = "external";

// Deprecated
export const AGREE_TO_ESIGN = "agree_esign";
export const EMPLOYEE_I9_ATTESTATION = "employee_i9_attestation";
export const EMPLOYER_I9_ATTESTATION = "employer_i9_attestation";
export const PREPARER_TRANSLATOR_ATTESTATION =
    "preparer_translator_attestation";
export const EMPLOYER_I9_REHIRE_ATTESTATION = "employer_i9_rehire_attestation";

// New
export const VIEWED_AGREE_TO_ESIGN = "viewed_agree_esign";
export const SIGNER_VIEWED_PAGE = "signer_viewed_page";
export const GENERATED_PREVIEW = "generated_preview";
export const VIEWED_EMPLOYEE_I9_ATTESTATION = "viewed_employee_i9_attestation";
export const SIGNER_VIEWED_SIGNATURE_ON_PAGE =
    "signer_viewed_signature_on_page";
export const UPDATED_SIGNATURE = "updated_signature";
export const VIEWED_PAPER_COPY_DISCLAIMER = "viewed_paper_copy_disclaimer";
export const VIEWED_TAKE_TIME_DISCLAIMER = "viewed_take_time_disclaimer";
export const VIEWED_PINCH_TO_ZOOM_DISCLAIMER =
    "viewed_pinch_to_zoom_disclaimer";
export const VIEWED_DOCUMENT_TITLE = "viewed_document_title";
export const EXPANDED_PAGES = "expanded_pages";

export const FEATHERY_BACK_TRANSLATIONS = ["BACK", "REGRESAR"];
export const RECORD_SOURCE_EXTERNAL = "external";
export const RECORD_SOURCE_INTERNAL = "internal";
