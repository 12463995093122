"use strict";

import angular from "angular";
import uirouter from "@uirouter/angularjs";
import { permission, uiPermission } from "angular-permission";
import ngSanitize from "angular-sanitize";
import angularFilter from "angular-filter";
import translate from "angular-translate";
import "angular-translate/dist/angular-translate-handler-log/angular-translate-handler-log";
import "angular-translate/dist/angular-translate-storage-cookie/angular-translate-storage-cookie";
import "angular-translate/dist/angular-translate-storage-local/angular-translate-storage-local";
import ngCookies from "angular-cookies";
import angularMoment from "angular-moment";
import "moment";
import "expose-loader?exposes=tv4!tv4";
import inputMask from "angular-input-masks";

let dependencies = [
    uirouter,
    permission,
    uiPermission,
    ngSanitize,
    angularFilter,
    translate,
    ngCookies,
    angularMoment,
    inputMask,
];

export default angular.module("app.core", dependencies).name;
