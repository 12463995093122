import template from "./qr_code.html";
import QRCode from "qrcode";

class QRCodeController {
    /* @ngInject */
    constructor($scope) {
        this.$scope = $scope;
    }

    $onInit() {
        let ctrl = this;
        QRCode.toDataURL(ctrl.content, { errorCorrectionLevel: "L" }).then(
            (url) => {
                ctrl.url = url;
                ctrl.$scope.$applyAsync();
            },
        );
    }
}

export default {
    bindings: {
        content: "<",
        classes: "@",
    },
    name: "qrCodeGenerator",
    controller: QRCodeController,
    template: template,
};
