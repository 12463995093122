import en from "./en.translation.json";
import es from "./es.translation.json";
import _ from "lodash";

import workerSelectSingleTemplate from "./user_select_single.html";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import {
    connectSearchBox,
    connectInfiniteHits,
} from "instantsearch.js/es/connectors";
import { configure } from "instantsearch.js/es/widgets";
import { SORTS } from "../../common.sorts";

export class WorkerSelectController {
    /* @ngInject */
    constructor($scope, GrowerMembershipService) {
        this.sortRules = SORTS;
        let ctrl = this;
        ctrl.$scope = $scope;
        this.GrowerMembershipService = GrowerMembershipService;
        $scope.$watchCollection("$ctrl.workers", function () {
            ctrl.search(ctrl.workers);
        });
    }

    $onInit() {
        let ctrl = this;

        const renderSearchBox = (renderOptions, isFirstRender) => {
            const { query, refine, clear, isSearchStalled, widgetParams } =
                renderOptions;
            if (isFirstRender) {
                ctrl.refine = refine;
            }
        };
        const customSearchBox = connectSearchBox(renderSearchBox);

        const renderHits = (renderOptions, isFirstRender) => {
            const { hits, isLastPage, showMore, results, currentPageHits } =
                renderOptions;
            let resultsByRelevance = [];
            for (let hit of hits) {
                let member = _.find(ctrl.workers, [
                    "growerMembership.sid",
                    hit.objectID,
                ]);
                if (member) {
                    resultsByRelevance.push(member);
                }
            }
            ctrl.filteredRoster = resultsByRelevance;
            ctrl.hasMore = !isLastPage;
            ctrl.showMore = showMore;
            ctrl.$scope.$applyAsync();
        };
        const customHits = connectInfiniteHits(renderHits);

        function generateFilterString() {
            return "grower_organization_sid:" + ctrl.grower.sid;
        }

        ctrl.GrowerMembershipService.getAlgoliaKey().then(function (data) {
            const searchClient = algoliasearch(
                process.env.ALGOLIA_APP_ID,
                data.public_key,
            );
            const search = instantsearch({
                indexName: "grower_membership_index",
                searchClient,
            });

            search.addWidgets([
                customSearchBox({}),
                customHits({}),
                configure({
                    filters: generateFilterString(),
                }),
            ]);

            search.on("error", () => {});

            ctrl.searchInstance = search;
            ctrl.search();
        });
    }

    toggleSort(params) {
        let ctrl = this;
        ctrl.orderBy = params.order_by;
        ctrl.search(ctrl.workers, ctrl.searchTerm);
    }

    search() {
        let ctrl = this;
        if (_.isEmpty(ctrl.searchQuery)) {
            let ordering = ctrl.sortRules[_.get(ctrl, "orderBy", "first_name")];
            ctrl.filteredRoster = _.orderBy(
                ctrl.workers,
                ordering.func,
                ordering.order,
            );
        } else {
            if (_.isUndefined(ctrl.refine)) {
                ctrl.searchInstance.start();
            }
            ctrl.refine(ctrl.searchQuery);
        }
    }

    update(results) {
        let ctrl = this;
        ctrl.onUpdate({ value: results });
    }
}

export default {
    bindings: { workers: "<", selectedWorker: "<", onUpdate: "&", grower: "<" },
    controller: WorkerSelectController,
    template: workerSelectSingleTemplate,
    name: "workerSelectSingle",
    translations: { en, es },
};
