import en from "./en.translation.json";
import es from "./es.translation.json";
import signinTemplate from "./signin.html";
import LOGO_URL from "../../../../../assets/harvust-logo-008.svg";
import mixpanel from "mixpanel-browser";
import { signinDoneState } from "../../../../app.routes";

class SigninController {
    /* @ngInject */
    constructor($state, AuthService) {
        this.AuthService = AuthService;
        this.$state = $state;
        this.LOGO_URL = LOGO_URL;
        this.showOTPLogin = false;
    }

    signin(credentials) {
        let ctrl = this;
        ctrl.AuthService.requestPasswordlessLogin(credentials).then(
            function (response) {
                mixpanel.track("Requested login link");
                ctrl.$state.go(signinDoneState, {
                    channel: response.data.signin_channel,
                });
            },
            function (reason) {
                ctrl.errors = reason.data;
            },
        );
    }

    toggleOTPLogin() {
        let ctrl = this;
        ctrl.showOTPLogin = !ctrl.showOTPLogin;
    }
}

export default {
    controller: SigninController,
    template: signinTemplate,
    bindings: { credentials: "<" },
    name: "signin",
    translations: { en, es },
};
