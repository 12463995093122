import template from "./transcription_input.html";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import _ from "lodash";
import recordingProcessor from "audio-worklet-loader!./recording-processor.worklet.js";
import es from "./es.json";
import en from "./en.json";
import {
    TranscribeStreamingClient,
    StartStreamTranscriptionCommand,
} from "@aws-sdk/client-transcribe-streaming";
import moment from "moment";
import promisePoller from "promise-poller";

const pcmEncode = (input) => {
    const buffer = new ArrayBuffer(input.length * 2);
    const view = new DataView(buffer);
    for (let i = 0; i < input.length; i++) {
        const s = Math.max(-1, Math.min(1, input[i]));
        view.setInt16(i * 2, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    }
    return buffer;
};

const AUDIO_INPUT_MODE = "audio";
const TEXT_INPUT_MODE = "text";

async function* createAudioDataIterator(port) {
    const messageQueue = [];
    const pendingResolvers = [];

    const messageHandler = (event) => {
        if (event.data.message === "SHARE_RECORDING_BUFFER") {
            const abuffer = pcmEncode(event.data.buffer[0]);
            const audiodata = new Uint8Array(abuffer);
            let data = {
                AudioEvent: {
                    AudioChunk: audiodata,
                },
            };

            if (pendingResolvers.length > 0) {
                const resolve = pendingResolvers.shift();
                resolve(data);
            } else {
                messageQueue.push(data);
            }
        }
    };

    const errorHandler = (error) => {
        if (pendingResolvers.length > 0) {
            const reject = pendingResolvers.shift();
            reject(error);
        } else {
            console.error("Error in message port:", error);
        }
    };

    port.onmessage = messageHandler;
    port.onmessageerror = errorHandler;

    try {
        while (true) {
            if (messageQueue.length > 0) {
                yield messageQueue.shift();
            } else {
                yield await new Promise((resolve, reject) => {
                    pendingResolvers.push(resolve);
                });
            }
        }
    } finally {
        port.removeEventListener("message", messageHandler);
        port.removeEventListener("messageerror", errorHandler);
    }
}

class TranscriptionInputController {
    /* @ngInject */
    constructor($window, $translate, $timeout, $scope, $q) {
        this.$window = $window;
        this.$translate = $translate;
        this.$timeout = $timeout;
        this.$scope = $scope;
        this.$q = $q;
        this.lines = [];
        this.recordingInProgress = false;
        this.partialLine = "";
        this.streamEventTimestamps = [];
        this.inputMode = AUDIO_INPUT_MODE;
        this.AUDIO_INPUT_MODE = AUDIO_INPUT_MODE;
        this.TEXT_INPUT_MODE = TEXT_INPUT_MODE;
    }

    async start() {
        let ctrl = this;

        ctrl.stream = await ctrl.$window.navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true,
        });

        let audioContext = new ctrl.$window.AudioContext();
        await audioContext.audioWorklet.addModule(recordingProcessor);

        ctrl.audioRecorder = new AudioWorkletNode(
            audioContext,
            "recording-processor",
            {
                processorOptions: {
                    numberOfChannels: 1,
                    sampleRate: audioContext.sampleRate,
                    maxFrameCount: (audioContext.sampleRate * 1) / 10,
                },
            },
        );

        ctrl.audioRecorder.port.postMessage({
            message: "UPDATE_RECORDING_STATE",
            setRecording: true,
        });

        const source1 = audioContext.createMediaStreamSource(ctrl.stream);
        const destination = audioContext.createMediaStreamDestination();
        source1.connect(ctrl.audioRecorder).connect(destination);
        const audioDataIterator = createAudioDataIterator(
            ctrl.audioRecorder.port,
        );

        const cognitoIdentityClient = new CognitoIdentityClient({
            region: process.env.AWS_COGNITO_REGION,
        });
        const creds = fromCognitoIdentityPool({
            client: cognitoIdentityClient,
            identityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID,
        });
        const clientConfig = {
            region: process.env.AWS_COGNITO_REGION,
            credentials: creds,
        };
        ctrl.transcribeClient = new TranscribeStreamingClient(clientConfig);
        const command = new StartStreamTranscriptionCommand({
            IdentifyLanguage: true,
            PreferredLanguage: ctrl.$translate.use() + "-US",
            LanguageOptions: "en-US,es-US",
            MediaEncoding: "pcm",
            MediaSampleRateHertz: audioContext.sampleRate,
            AudioStream: audioDataIterator,
        });
        const data = await ctrl.transcribeClient.send(command);
        console.log("Transcribe sesssion established");

        ctrl.$scope.$applyAsync(function () {
            ctrl.recordingInProgress = true;
            ctrl.showSpinner = false;
        });
        if (data.TranscriptResultStream) {
            for await (const event of data.TranscriptResultStream) {
                ctrl.streamEventTimestamps.push(moment());
                if (event?.TranscriptEvent?.Transcript) {
                    for (const result of event?.TranscriptEvent?.Transcript
                        .Results || []) {
                        if (
                            result?.Alternatives &&
                            result?.Alternatives[0].Items
                        ) {
                            let completeSentence = ``;
                            for (
                                let i = 0;
                                i < result?.Alternatives[0].Items?.length;
                                i++
                            ) {
                                completeSentence += ` ${result?.Alternatives[0].Items[i].Content}`;
                            }
                            if (!result.IsPartial) {
                                ctrl.lines.push(completeSentence.trim());
                                ctrl.partialLine = "";
                            } else {
                                ctrl.partialLine = completeSentence.trim();
                            }
                        }
                    }
                }
            }
        }
    }

    finish() {
        let ctrl = this;
        console.log("Finishing recording");
        ctrl.$timeout(function () {
            ctrl.stop();
        }, 1000).then(function () {
            console.log("Recording stopped");
            promisePoller({
                retries: 50,
                interval: 500,
                taskFn: function () {
                    console.log("Polling for transcription wait");
                    let timeSinceLastEvent = moment().diff(
                        _.last(ctrl.streamEventTimestamps),
                        "milliseconds",
                    );
                    console.log(`Time since last event: ${timeSinceLastEvent}`);
                    return new Promise((resolve, reject) => {
                        if (timeSinceLastEvent > 2000) {
                            resolve({
                                message:
                                    "Enough time has passed since last event",
                            });
                        } else {
                            reject({
                                message:
                                    "Not enough time has passed since last event",
                            });
                        }
                    });
                },
            }).then(function () {
                ctrl.lines.push(ctrl.partialLine);
                let finalText = _.join(ctrl.lines, " ");
                console.log("Final text:");
                console.log(finalText);
                ctrl.onUpdate({ text: finalText });
                ctrl.lines = [];
                if (ctrl.transcribeClient) {
                    ctrl.transcribeClient.destroy();
                }
            });
        });
    }

    stop() {
        let ctrl = this;
        if (ctrl.audioRecorder) {
            ctrl.audioRecorder.port.postMessage({
                message: "UPDATE_RECORDING_STATE",
                setRecording: false,
            });
            ctrl.audioRecorder.port.close();
            ctrl.audioRecorder.disconnect();
        }

        if (ctrl.stream) {
            let tracks = ctrl.stream.getTracks();
            tracks.forEach((track) => track.stop());
        }
    }

    toggleRecording() {
        let ctrl = this;
        if (ctrl.recordingInProgress) {
            ctrl.recordingInProgress = false;
            ctrl.showSpinner = true;
            ctrl.finish();
        } else {
            ctrl.showSpinner = true;
            ctrl.start();
        }
    }

    $onDestroy() {
        let ctrl = this;
        ctrl.stop();
    }

    toggleInputMode(mode) {
        let ctrl = this;
        ctrl.inputMode = mode;
        if (mode === ctrl.TEXT_INPUT_MODE) {
        } else if (mode === ctrl.AUDIO_INPUT_MODE) {
            ctrl.body = "";
        }
    }

    submitText(body) {
        let ctrl = this;
        if (body) {
            ctrl.onUpdate({ text: body });
        }
    }
}

export default {
    bindings: {
        onUpdate: "&",
    },
    name: "transcriptionInput",
    controller: TranscriptionInputController,
    template: template,
    translations: { es, en },
};
