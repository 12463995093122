"use strict";

import angular from "angular";
import core from "../../core.module";
import _ from "lodash";
import components from "./components";

let module = angular.module("app.publicWafla", [core]);

_.forEach(components, function (component) {
    module.component(component.name, component);
});

module.config(function ($translateProvider) {
    "ngInject";
    _.forEach(components, function (component) {
        _.forEach(component.translations, function (table, language) {
            $translateProvider.translations(language, table);
        });
    });
});

export default module;
