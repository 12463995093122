import template from "./section_heading.html";

export default {
    bindings: {
        headline: "@",
        description: "@",
    },
    template: template,
    name: "sectionHeading",
};
