export const WORKER_COMMENT = "comment";
export const NOTE = "note";
export const AUDIO = "audio";
export const VIDEO = "video";
export const TEXT = "text";
export const SLIDES = "slides";

export const REMOTE = "remote";
export const IN_PERSON = "inperson";

export const NOW = "now";
export const SCHEDULED = "scheduled";
