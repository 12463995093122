"use strict";

import _ from "lodash";
import { SID_LOOKUP } from "../../app.constants";
import { saveAs } from "file-saver";
import { zip } from "fflate";
import { Notyf } from "notyf";
import { underscoreSanitize } from "./common.utils";

export class CanvasService {
    /* @ngInject */
    constructor($q) {
        this.$q = $q;
    }

    getBlob(canvas, quality = 0.9) {
        let svc = this;
        let deferred = svc.$q.defer();
        canvas.toBlob(
            (blob) => {
                deferred.resolve(blob);
            },
            "image/jpeg",
            quality,
        );
        return deferred.promise;
    }
}

export class ToastService {
    /* @ngInject */
    constructor($translate) {
        this.$translate = $translate;
        const TOAST_DURATION = 4000;
        this.notyf = new Notyf({
            duration: TOAST_DURATION,
            position: {
                x: "left",
                y: "bottom",
            },
            types: [
                {
                    type: "success",
                    icon: false,
                    background: "#323232",
                    className: "text-base",
                },
                {
                    type: "error",
                    icon: false,
                    className: "text-base",
                },
            ],
        });
        let ctrl = this;
        ctrl.create = _.throttle(function (
            translationKey,
            values = {},
            options = {},
        ) {
            if (!_.isEmpty(translationKey)) {
                return this.$translate(translationKey, values).then(
                    function (message) {
                        options.message = message;
                        return ctrl.notyf.success(options);
                    },
                );
            }
        }, TOAST_DURATION);
    }
}

export class DownloadService {
    /* @ngInject */
    constructor($q, $http) {
        this.$q = $q;
        this.$http = $http;
    }

    getBlob(url) {
        let svc = this;
        return svc.$http.get(url, {
            responseType: "blob",
        });
    }

    get(url, additionalProperties) {
        let svc = this;
        return svc.$http
            .get(url, { responseType: "arraybuffer" })
            .then((resp) => _.merge({ data: resp.data }, additionalProperties));
    }

    baseZip(formatted) {
        let svc = this;
        let deferred = svc.$q.defer();
        zip(formatted, { level: 0 }, (err, dat) =>
            err ? deferred.reject(err) : deferred.resolve(dat),
        );
        return deferred.promise;
    }

    zip(files) {
        let svc = this;
        let newZipFormattedData = {};
        _.forEach(files, function (file) {
            let filename = underscoreSanitize(file.name) + "." + file.extension;
            newZipFormattedData[filename] = new Uint8Array(file.data);
        });
        return svc.baseZip(newZipFormattedData);
    }

    save(zipped, fileName) {
        saveAs(new Blob([zipped.buffer]), fileName + ".zip");
    }

    fetchAndSave(fileSpecs, fileName) {
        let svc = this;
        let fileResponse = _.map(fileSpecs, function (file) {
            return svc.get(file.url, file);
        });
        return svc.$q.all(fileResponse).then(function (documents) {
            return svc.zip(documents).then(function (zipped) {
                svc.save(zipped, fileName);
            });
        });
    }
}

export class ResourceService {
    /* @ngInject */
    constructor(Restangular, $q, resource, slug_field = SID_LOOKUP) {
        this.Restangular = Restangular;
        this.$q = $q;
        this.resource = resource;
        this.slug_field = slug_field;
        this.SEVERAL_MAX = 50;
    }

    createInstance(sid) {
        let svc = this;
        return svc.Restangular.one(svc.resource, sid);
    }

    create(data) {
        return this.Restangular.all(this.resource).post(data);
    }

    retrieve(sid, params = {}, options = {}) {
        let expand = _.get(options, "expand", []);
        if (!_.isEmpty(expand)) {
            params.expand = _.join(expand);
        }
        if (sid) {
            return this.Restangular.one(this.resource, sid).get(params);
        }
    }

    list(params = {}, options = {}) {
        let orderBy = _.get(options, "orderBy", undefined);
        let ascending = _.get(options, "ascending", true);
        let expand = _.get(options, "expand", []);
        let emptyIn = false;
        _.forEach(params, function (value, key) {
            if (_.endsWith(key, "__in") && _.isEmpty(value)) {
                emptyIn = true;
            }
        });
        if (orderBy) {
            let orderByParam = orderBy;
            if (!ascending) {
                orderByParam = "-" + orderByParam;
            }
            params.order_by = orderByParam;
        }
        if (!_.isEmpty(expand)) {
            params.expand = _.join(expand);
        }
        if (emptyIn) {
            let deferred = this.$q.defer();
            deferred.resolve([]);
            return deferred.promise;
        } else {
            return this.Restangular.all(this.resource).getList(params);
        }
    }

    several(many) {
        let svc = this;
        let params = {};
        let chunks = _.chunk(_.uniq(many), svc.SEVERAL_MAX);
        let responses = _.map(chunks, function (chunkOfResponses) {
            params[svc.slug_field + "__in"] = _.join(chunkOfResponses);
            return svc.list(params);
        });
        return svc.$q
            .all(responses)
            .then(_.flatten)
            .then(function (results) {
                return svc.Restangular.restangularizeCollection(
                    undefined,
                    results,
                    svc.resource,
                    true,
                );
            });
    }

    head(params = {}, orderBy, limit, ascending = true) {
        return this.list(params, {
            orderBy: orderBy,
            ascending: ascending,
        }).then((results) => _.take(results, limit));
    }

    first(params = {}, orderBy, ascending = true) {
        return this.list(params, {
            orderBy: orderBy,
            ascending: ascending,
        }).then(_.first);
    }
}

export const cloudinaryUploadWidgetStyles = {
    palette: {
        window: "#FFF",
        windowBorder: "rgba(94, 65, 15, 0.7)",
        tabIcon: "#CC8B1D",
        menuIcons: "#5E410F",
        textDark: "#000000",
        textLight: "#FFFFFF",
        link: "rgba(94, 65, 15, 0.7)",
        action: "#CC8B1D",
        inactiveTabIcon: "rgba(94, 65, 15, 0.7)",
        error: "#F44235",
        inProgress: "#0078FF",
        complete: "#8FC73B",
        sourceBg: "#E4EBF1",
    },
};

export const cloudinaryProfilePictureConfig = {
    cloudName: "harvust",
    uploadPreset: "profile_picture",
    sources: ["camera", "local"],
    defaultSource: "camera",
    styles: cloudinaryUploadWidgetStyles,
    showPoweredBy: false,
};

export const cloudinaryLogoPictureConfig = {
    cloudName: "harvust",
    uploadPreset: "business_detail_logo",
    sources: ["local"],
    defaultSource: "local",
    styles: cloudinaryUploadWidgetStyles,
    showPoweredBy: false,
};

export const cloudinaryAttachmentsConfig = {
    cloudName: "harvust",
    uploadPreset: "attachments",
    sources: ["local"],
    defaultSource: "local",
    styles: cloudinaryUploadWidgetStyles,
    showPoweredBy: false,
};
