"use strict";

import angular from "angular";

import translationsEN from "./translations/en.json";
import translationsES from "./translations/es.json";
import { ENGLISH, SPANISH } from "../../app.constants";

let module = angular.module("common.notify", []);
module.config(function ($translateProvider) {
    "ngInject";
    $translateProvider.translations(ENGLISH, translationsEN);
    $translateProvider.translations(SPANISH, translationsES);
});

export default module;
