import en from "./en.translation.json";
import es from "./es.translation.json";
import template from "./otp_login.html";
import LOGO_URL from "../../../../../assets/harvust-logo-008.svg";
import mixpanel from "mixpanel-browser";
import { signinDoneState } from "../../../../app.routes";

class OTPLoginController {
    /* @ngInject */
    constructor($state, AuthService, $window) {
        this.AuthService = AuthService;
        this.$state = $state;
        this.$window = $window;
        this.LOGO_URL = LOGO_URL;
        this.otpInput = {};
    }

    requestCode(credentials) {
        let ctrl = this;
        ctrl.showRequestCodeSpinner = true;
        ctrl.AuthService.otpRequestCode(credentials).then(
            function (response) {
                mixpanel.track("Requested otp code");
                ctrl.showCodeInput = true;
                ctrl.otpInput.mobile_email = credentials.mobile_email;
                ctrl.showRequestCodeSpinner = false;
            },
            function (reason) {
                ctrl.errors = reason.data;
                ctrl.showRequestCodeSpinner = false;
            },
        );
    }

    verifyCode(data) {
        let ctrl = this;
        ctrl.showVerifyCodeSpinner = true;
        ctrl.AuthService.otpVerifyCode(data).then(
            function (response) {
                ctrl.$window.location = response.data.redirect_url;
            },
            function (reason) {
                ctrl.errors = reason.data;
                ctrl.showVerifyCodeSpinner = false;
            },
        );
    }
}

export default {
    controller: OTPLoginController,
    template: template,
    bindings: { credentials: "<" },
    name: "otpLogin",
    translations: { en, es },
};
