import { captureMessage } from "@sentry/browser";
import _ from "lodash";
import moment from "moment";

export const SORTS = {
    first_name: {
        label: "common.sorts.SORT_BY_FIRST_NAME",
        params: { order_by: "first_name" },
        func: function (element) {
            if (_.isUndefined(element.growerMembership)) {
                captureMessage("Grower membership is undefined", {
                    contexts: {
                        error: {
                            element: element,
                            user: element.user.sid,
                            profile: element.user.profile,
                        },
                    },
                });
            }
            return _.toLower(_.get(element, "growerMembership.first_name", ""));
        },
        order: "asc",
    },
    last_name: {
        label: "common.sorts.SORT_BY_LAST_NAME",
        params: { order_by: "last_name" },
        func: function (element) {
            if (_.isUndefined(element.growerMembership)) {
                captureMessage("Grower membership is undefined", {
                    contexts: {
                        error: {
                            element: element,
                            user: element.user.sid,
                            profile: element.user.profile,
                        },
                    },
                });
            }
            return _.toLower(_.get(element, "growerMembership.last_name", ""));
        },
        order: "asc",
    },
    date_added: {
        label: "common.sorts.SORT_BY_DATE_ADDED",
        params: { order_by: "date_added" },
        func: function (element) {
            return moment(element.growerMembership.created);
        },
        order: "desc",
    },
    name: {
        func: function (element) {
            if (_.isUndefined(element.growerMembership)) {
                captureMessage("Grower membership is undefined", {
                    contexts: {
                        error: {
                            element: element,
                            user: element.user.sid,
                            profile: element.user.profile,
                        },
                    },
                });
            }
            return _.toLower(
                _.get(element, "growerMembership.first_name", "") +
                    " " +
                    _.get(element, "growerMembership.last_name", ""),
            );
        },
        order: "asc",
    },
    employee_id: {
        label: "common.sorts.SORT_BY_EMPLOYEE_ID",
        params: { order_by: "employee_id" },
        func: function (element) {
            return element.growerMembership.employee_id;
        },
        order: "asc",
    },
};
