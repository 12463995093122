import _ from "lodash";
import printJS from "print-js";
import moment from "moment";
import sanitize from "sanitize-filename";

import { Loader } from "@googlemaps/js-api-loader";

export function getGMapsAutocompletePlace(place) {
    let location = place.geometry.location;
    let addressToGo = {
        lat: Number(location.lat().toFixed(8)),
        lng: Number(location.lng().toFixed(8)),
    };
    if (_.has(place, "address_components")) {
        let addressComponents = place.address_components;
        let streetNumber = _.get(
            _.find(addressComponents, function (o) {
                return _.includes(o.types, "street_number");
            }),
            "short_name",
        );
        let streetName = _.get(
            _.find(addressComponents, function (o) {
                return _.includes(o.types, "route");
            }),
            "short_name",
        );
        addressToGo.street_address = streetNumber + " " + streetName;
        addressToGo.city = _.get(
            _.find(addressComponents, function (o) {
                return _.includes(o.types, "locality");
            }),
            "short_name",
        );
        addressToGo.state = _.get(
            _.find(addressComponents, function (o) {
                return _.includes(o.types, "administrative_area_level_1");
            }),
            "short_name",
        );
        addressToGo.zipcode = _.get(
            _.find(addressComponents, function (o) {
                return _.includes(o.types, "postal_code");
            }),
            "short_name",
        );
    }
    return addressToGo;
}

export function print(elementID) {
    printJS({
        printable: elementID,
        type: "html",
        targetStyles: ["*"],
        maxWidth: 2000,
        css: "https://fonts.googleapis.com/icon?family=Material+Icons",
    });
}
export function combineFilters(incomingFilters) {
    let filters = {};
    for (let filter of incomingFilters) {
        let result = filter.out;
        let name = filter.name;
        let newParam;
        if (!_.isEmpty(result)) {
            newParam = result;
        } else {
            newParam = undefined;
        }
        filters[name] = newParam;
    }
    return filters;
}

export function formatDatetime(date, time) {
    return date + " " + time;
}

export function getCloudinaryUrl(public_id) {
    return "https://res.cloudinary.com/harvust/image/upload/" + public_id;
}

export function lazyLoad(transition, ...urls) {
    urls = _.map(urls, function (url) {
        return { type: "js", path: url };
    });
    return transition.injector().get("$ocLazyLoad").load(urls);
}

export function getContactWorkerDisplayName(conversation, growerMembership) {
    if (conversation.is_anonymous) {
        return "Anonymous";
    } else {
        return growerMembership.first_name + " " + growerMembership.last_name;
    }
}

export function getLatestMembership(memberships, userSid) {
    let relevantGrowerMemberships = _.filter(memberships, ["user", userSid]);
    return _.last(
        _.orderBy(relevantGrowerMemberships, function (membership) {
            return moment(membership.created);
        }),
    );
}

export function underscoreSanitize(content) {
    return sanitize(content, { replacement: "_" });
}

export function downloadFile(filePath, newTab = false) {
    var link = document.createElement("a");
    link.href = filePath;
    link.rel = "noopener";
    if (newTab) {
        link.target = "_blank";
    }
    link.click();
}

export const g_maps_loader = new Loader({
    apiKey: process.env.GOOGLE_PUBLIC_API_KEY,
    version: "weekly",
    libraries: ["places"],
});

export function prepend(value, array) {
    var newArray = array.slice();
    newArray.unshift(value);
    return newArray;
}

export function urlParamFilter(collection, availableFilters, params) {
    return _.filter(collection, function (element) {
        let filterResponses = _.map(
            availableFilters,
            function (filterConfig, name) {
                let filterInURL = params[name];
                let flag;
                if (filterInURL) {
                    flag = filterConfig.func(element, filterInURL);
                } else {
                    flag = true;
                }
                return flag;
            },
        );
        return _.every(filterResponses);
    });
}

export function urlParamSort(collection, sorts, orderingKey) {
    let ordering = sorts[orderingKey];
    return _.orderBy(collection, ordering.func, ordering.order);
}
