"use strict";
import angular from "angular";
import dateInputTemplate from "./masked_date_input.html";
import ssnInputTemplate from "./masked_social_security_input.html";
import zipcodeInputTemplate from "./masked_zip_code_input.html";
angular
    .module("schemaForm")
    .run([
        "$templateCache",
        function ($templateCache) {
            $templateCache.put(
                "directives/decorators/bootstrap/date/date-input-mask.html",
                dateInputTemplate,
            );
            $templateCache.put(
                "directives/decorators/bootstrap/ssn/ssn-input-mask.html",
                ssnInputTemplate,
            );
            $templateCache.put(
                "directives/decorators/bootstrap/zipcode/zipcode-input-mask.html",
                zipcodeInputTemplate,
            );
        },
    ])
    .config(
        function (
            schemaFormProvider,
            schemaFormDecoratorsProvider,
            sfPathProvider,
            sfBuilderProvider,
        ) {
            "ngInject";

            schemaFormDecoratorsProvider.defineAddOn(
                "bootstrapDecorator", // Name of the decorator you want to add to.
                "date", // Form type that should render this add-on
                "directives/decorators/bootstrap/date/date-input-mask.html", // Template name in $templateCache
                sfBuilderProvider.stdBuilders, // List of builder functions to apply.
            );

            schemaFormDecoratorsProvider.defineAddOn(
                "bootstrapDecorator", // Name of the decorator you want to add to.
                "ssn", // Form type that should render this add-on
                "directives/decorators/bootstrap/ssn/ssn-input-mask.html", // Template name in $templateCache
                sfBuilderProvider.stdBuilders, // List of builder functions to apply.
            );

            schemaFormDecoratorsProvider.defineAddOn(
                "bootstrapDecorator", // Name of the decorator you want to add to.
                "zipcode", // Form type that should render this add-on
                "directives/decorators/bootstrap/zipcode/zipcode-input-mask.html", // Template name in $templateCache
                sfBuilderProvider.stdBuilders, // List of builder functions to apply.
            );
        },
    );
