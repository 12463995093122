"use strict";

import angular from "angular";
import core from "../../core.module";
import api from "../api";
import common from "../common";
import {
    alienNumberInput,
    alienNumberMask,
    countryInput,
    dateInput,
    emailInput,
    formSelectInput,
    formTextInput,
    i94Input,
    i94Mask,
    middleInitialInput,
    middleInitialMask,
    paperworkDateMask,
    phoneInput,
    socialInput,
    stateInput,
    uiUsSocialSecurityMask,
    uiUsZipCodeMask,
    zipCodeInput,
    observeMe,
} from "./paperwork.directives";
import "angular-schema-form/src/schema-form.module";
import "angular-schema-form-bootstrap";
import _ from "lodash";
import components from "./components";
import "../../common/paperwork/paperwork.config";
import { ENGLISH, SPANISH } from "../../app.constants";
import translationsEN from "./translations/en.translation.json";
import translationsES from "./translations/es.translation.json";

let module = angular
    .module("common.paperwork", [core, api.name, common.name, "schemaForm"])
    .directive("observeMe", observeMe)
    .directive("uiUsSocialSecurityMask", uiUsSocialSecurityMask)
    .directive("uiUsZipCodeMask", uiUsZipCodeMask)
    .directive("dateInput", dateInput)
    .directive("zipCodeInput", zipCodeInput)
    .directive("socialInput", socialInput)
    .directive("emailInput", emailInput)
    .directive("phoneInput", phoneInput)
    .directive("stateInput", stateInput)
    .directive("formTextInput", formTextInput)
    .directive("formSelectInput", formSelectInput)
    .directive("middleInitialMask", middleInitialMask)
    .directive("middleInitialInput", middleInitialInput)
    .directive("i94Mask", i94Mask)
    .directive("i94Input", i94Input)
    .directive("countryInput", countryInput)
    .directive("alienNumberMask", alienNumberMask)
    .directive("alienNumberInput", alienNumberInput)
    .directive("paperworkDateMask", paperworkDateMask);

_.forEach(components, function (component) {
    module.component(component.name, component);
});
module.config(function ($translateProvider) {
    "ngInject";
    $translateProvider.translations(ENGLISH, translationsEN);
    $translateProvider.translations(SPANISH, translationsES);
});

module.config(function ($translateProvider) {
    "ngInject";

    _.forEach(components, function (component) {
        _.forEach(component.translations, function (table, language) {
            $translateProvider.translations(language, table);
        });
    });
});

export default module;
export { getFeatheryFieldsWithErrors } from "./paperwork.utils";
export { focusFirstFieldWithError } from "./paperwork.utils";
