import { signinState } from "../../app.routes";
import _ from "lodash";
import { BAD_REQUEST, UNAUTHORIZED } from "../common/common.constants";
import { addBreadcrumb, captureException } from "@sentry/browser";
import promisePoller, { CANCEL_TOKEN } from "promise-poller";

export class AuthService {
    /* @ngInject */
    constructor(
        $http,
        localStorageService,
        $state,
        $cookies,
        PermPermissionStore,
        $q,
        jwtHelper,
        $location,
        $window,
        $urlService,
    ) {
        this.$http = $http;
        this.$state = $state;
        this.$location = $location;
        this.endpoint = process.env.API_DOMAIN + "/api/auth";
        this.localStorageService = localStorageService;
        this.authRequestConfig = { skipAuthorization: true };
        this.$cookies = $cookies;
        this.PermPermissionStore = PermPermissionStore;
        this.$q = $q;
        this.jwtHelper = jwtHelper;
        this.$window = $window;
        this.loggedInFlagCookieName = "isLoggedIn";
        this.refreshTokenKey = "refresh";
        this.urlAuthTokenKey = "url_auth_token";
        this.urlService = $urlService;
        this.ignoreStates = ["signin", "signin.done", "cookies", "reset"];
        this.offsetSeconds = 30; //Needed for lag between when a token is considered expired and when the server can reasonably respond
        this.allowUnauthenticatedStates = [
            "reset",
            "takeProfilePicture",
            "visatrace",
            "publicListTraining",
            "publicListTraining.publicTraining",
        ];
        this.inMemoryAccessToken = null;
    }

    redirectToLogin() {
        let svc = this;
        let urlParts = svc.urlService.parts();
        let matched = svc.urlService.match(urlParts);
        let matchedStateName = _.has(matched, "rule.state.name");
        let params = {};
        if (matchedStateName) {
            if (!_.includes(svc.ignoreStates, matched.rule.state.name)) {
                params.redirect_to = svc.urlService.path();
            }
        }

        try {
            params.mobile_email = svc.jwtHelper.decodeToken(
                svc.$location.search()[svc.urlAuthTokenKey],
            ).mobile_email;
        } catch {}

        if (matchedStateName) {
            if (
                !_.includes(
                    _.concat(svc.allowUnauthenticatedStates, svc.ignoreStates),
                    matched.rule.state.name,
                )
            ) {
                svc.$state.go(signinState, params);
            }
        }
    }

    clearStorage() {
        let svc = this;
        addBreadcrumb({
            category: "auth",
            message: "Clearing storage",
        });
        svc.setAccessToken(null);
        svc.localStorageService.remove(svc.refreshTokenKey);
        svc.$cookies.remove(svc.loggedInFlagCookieName, {
            path: "/",
            domain: process.env.ROOT_DOMAIN,
        });
        svc.PermPermissionStore.clearStore();
    }

    logout() {
        let svc = this;
        svc.clearStorage();
        svc.redirectToLogin();
    }

    checkAccessToken() {
        let svc = this;
        let response = svc.$q.defer();
        let accessToken = svc.retrieveAccessToken();
        if (!accessToken) {
            addBreadcrumb({
                category: "auth",
                message:
                    "No access token found: " + JSON.stringify(accessToken),
            });
            response.reject("No access token found: " + accessToken);
        } else if (
            svc.jwtHelper.isTokenExpired(accessToken, svc.offsetSeconds)
        ) {
            addBreadcrumb({
                category: "auth",
                message: "Access token expired: " + JSON.stringify(accessToken),
            });
            response.reject("Access token expired: " + accessToken);
        } else {
            addBreadcrumb({
                category: "auth",
                message: "Access token is good: " + JSON.stringify(accessToken),
            });
            response.resolve(accessToken);
        }
        return response.promise;
    }

    filterValidTokens(tokens) {
        let svc = this;
        return _.filter(tokens, function (token) {
            try {
                return !svc.jwtHelper.isTokenExpired(token, svc.offsetSeconds);
            } catch (e) {
                addBreadcrumb({
                    category: "auth",
                    message:
                        "Token value during valid fitlering caused exception: " +
                        JSON.stringify(token),
                    data: e,
                });
                return false;
            }
        });
    }

    start() {
        let svc = this;
        let urlToken = svc.getTokenFromURL();
        let currentToken = svc.retrieveRefreshToken();
        let foundTokens = [urlToken, currentToken];
        let validTokens = svc.filterValidTokens(foundTokens);
        return svc.pickToken(validTokens);
    }

    pickToken(validTokens) {
        let svc = this;
        let response = svc.$q.defer();
        let token = validTokens[0];
        if (token) {
            response.resolve(token);
        } else {
            response.reject("No valid token available in url or storage");
        }
        return response.promise;
    }

    getAuthenticatedUserSid(token) {
        let svc = this;
        let deferred = svc.$q.defer();
        if (!token) {
            token = svc.retrieveRefreshToken();
        }
        try {
            let userSid = svc.jwtHelper.decodeToken(token).user_sid;
            deferred.resolve(userSid);
        } catch (error) {
            deferred.reject("Unable to get current user sid");
        }
        return deferred.promise;
    }

    tokenGetter() {
        let svc = this;
        return svc
            .checkAccessToken()
            .catch(function (reason) {
                addBreadcrumb({
                    category: "auth",
                    message: reason,
                });
                if (svc.refreshingAccessTokenPromise) {
                    addBreadcrumb({
                        category: "auth",
                        message: "Access token refresh request in-progress...",
                    });
                    return svc.refreshingAccessTokenPromise;
                } else {
                    let refresh_token = svc.retrieveRefreshToken();
                    addBreadcrumb({
                        category: "auth",
                        message: "Initiating access token refresh request",
                    });
                    svc.refreshingAccessTokenPromise = promisePoller({
                        taskFn: function () {
                            return svc.$http
                                .post(
                                    svc.endpoint + "/refresh/",
                                    { refresh: refresh_token },
                                    svc.authRequestConfig,
                                )
                                .then(
                                    function (response) {
                                        let data = response.data;
                                        addBreadcrumb({
                                            category: "auth",
                                            message:
                                                "Successfully refreshed! Setting tokens",
                                        });
                                        svc.setTokens(
                                            data.access,
                                            data.refresh,
                                        );
                                        svc.setLoggedInFlag();
                                    },
                                    function (response) {
                                        addBreadcrumb({
                                            category: "auth",
                                            message:
                                                "Received an error when refreshing access token",
                                            data: {
                                                status: response.status,
                                            },
                                        });
                                        let resp = svc.$q.defer();
                                        if (
                                            response.status === UNAUTHORIZED ||
                                            response.status === BAD_REQUEST
                                        ) {
                                            console.log("bad request see ya!");
                                            resp.reject(CANCEL_TOKEN);
                                        } else {
                                            resp.reject(response);
                                        }
                                        return resp.promise;
                                    },
                                );
                        },
                    });
                    return svc.refreshingAccessTokenPromise;
                }
            })
            .then(
                function () {
                    svc.refreshingAccessTokenPromise = undefined;
                    return svc.retrieveAccessToken();
                },
                function (reasons) {
                    console.log(
                        "Unable to refresh access token. Logging out...",
                    );
                    captureException(
                        "Unable to refresh access token. Logging out...",
                        {
                            contexts: {
                                error: {
                                    attempts: reasons.length,
                                    last_attempt: _.last(reasons),
                                },
                            },
                        },
                    );
                    svc.logout();
                    svc.refreshingAccessTokenPromise = undefined;
                    let deferred = svc.$q.defer();
                    deferred.reject({}); // return a rejection to prevent the request from being attempted
                    return deferred.promise;
                },
            );
    }

    retrieveAccessToken() {
        let svc = this;
        let token = svc.inMemoryAccessToken;
        addBreadcrumb({
            category: "auth",
            message: "Access token retrieved from memory",
            data: { token },
        });
        return token;
    }

    retrieveRefreshToken() {
        let svc = this;
        let token = svc.retrieveToken(svc.refreshTokenKey);
        addBreadcrumb({
            category: "auth",
            message: "Refresh token retrieved from storage",
            data: { token },
        });
        return token;
    }

    retrieveToken(key) {
        let svc = this;
        return svc.localStorageService.get(key);
    }

    setTokens(accessToken, refreshToken) {
        let svc = this;
        svc.setAccessToken(accessToken);
        svc.setRefreshToken(refreshToken);
    }

    setAccessToken(token) {
        let svc = this;
        addBreadcrumb({
            category: "auth",
            message: "Setting inMemoryAccessToken token",
            data: { token },
        });
        svc.inMemoryAccessToken = token;
    }

    setRefreshToken(token) {
        let svc = this;
        svc.setToken(token, svc.refreshTokenKey);
    }

    setToken(token, key) {
        let svc = this;
        addBreadcrumb({
            category: "auth",
            message: "Setting " + key + " token",
        });
        svc.localStorageService.set(key, token);
    }

    setLoggedInFlag() {
        let svc = this;
        svc.$cookies.put(svc.loggedInFlagCookieName, true, {
            domain: process.env.ROOT_DOMAIN,
            samesite: "lax",
            secure: true,
        });
    }

    requestPasswordlessLogin(credentials) {
        let svc = this;
        return svc.$http.post(
            svc.endpoint + "/signin/",
            credentials,
            svc.authRequestConfig,
        );
    }

    getTokenFromURL() {
        let svc = this;
        let token = svc.$location.search()[svc.urlAuthTokenKey];
        svc.$location.search(svc.urlAuthTokenKey, null);
        addBreadcrumb({
            category: "auth",
            message: "Token retrieved from URL",
            data: { token },
        });
        return token;
    }

    otpRequestCode(data) {
        let svc = this;
        return svc.$http.post(
            svc.endpoint + "/otp-request/",
            data,
            svc.authRequestConfig,
        );
    }

    otpVerifyCode(data) {
        let svc = this;
        return svc.$http.post(
            svc.endpoint + "/otp-verify/",
            data,
            svc.authRequestConfig,
        );
    }
}
