import signinDoneTemplate from "./signin_done.html";
import en from "./en.translation.json";
import es from "./es.translation.json";
import LOGIN_ICON_URL from "../../assets/003.svg";

class SigninDoneController {
    /* @ngInject */
    constructor() {
        this.LOGIN_ICON_URL = LOGIN_ICON_URL;
    }
}

export default {
    bindings: { channel: "<" },
    controller: SigninDoneController,
    template: signinDoneTemplate,
    name: "signinDone",
    translations: { en, es },
};
