import logoUrl from "../assets/harvust-logo-008.svg";
import frameTemplate from "./frame.html";
import navDropdownTemplate from "./nav_dropdown.html";
import mixpanel from "mixpanel-browser";

class NavDropDownController {
    /* @ngInject */
    constructor($document, $element, $timeout, AuthService, BeamsService) {
        this.AuthService = AuthService;
        this.BeamsService = BeamsService;
        this.$document = $document;
        this.$element = $element;
        this.$timeout = $timeout;
        this.expanded = false;
        let ctrl = this;
        ctrl.$document.on("click", function (event) {
            if (ctrl.expanded) {
                ctrl.$timeout(function () {
                    let contents = ctrl.$element.find("*");
                    ctrl.expanded = Array.from(contents).includes(event.target);
                });
            }
        });
    }

    logout() {
        let ctrl = this;
        mixpanel.track("Clicked sign out");
        ctrl.AuthService.logout();
        ctrl.BeamsService.stop();
    }
}
export const NavDropdownComponent = {
    controller: NavDropDownController,
    bindings: { account: "<" },
    template: navDropdownTemplate,
};

class GlobalController {
    /* @ngInject */
    constructor(AuthService, BeamsService, $rootScope) {
        this.LOGO_URL = logoUrl;
        this.AuthService = AuthService;
        this.BeamsService = BeamsService;
        this.$rootScope = $rootScope;
    }

    logout() {
        let ctrl = this;
        mixpanel.track("Clicked sign out");
        ctrl.AuthService.logout();
        ctrl.BeamsService.stop();
    }
}

export const FrameComponent = {
    controller: GlobalController,
    bindings: { account: "<" },
    template: frameTemplate,
};
