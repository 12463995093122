import _ from "lodash";
import workerSelectTemplate from "./user_select.html";
import en from "./en.translation.json";
import es from "./es.translation.json";
import { WorkerSelectController } from "../workerSelectSingle";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import {
    connectSearchBox,
    connectInfiniteHits,
} from "instantsearch.js/es/connectors";
import { configure } from "instantsearch.js/es/widgets";

export class WorkerSelectMultiController extends WorkerSelectController {
    /* @ngInject */
    constructor($scope, GrowerMembershipService) {
        super($scope, GrowerMembershipService);
        this.selectAll = false;
    }

    updateSelectAll() {
        let ctrl = this;
        ctrl.selectAll =
            ctrl.selectedWorkers.length === ctrl.filteredRoster.length;
    }

    toggleAll(value) {
        let ctrl = this;
        let selected;
        if (value) {
            selected = [];
        } else {
            selected = _.clone(ctrl.filteredRoster);
        }
        ctrl.selectedWorkers = _.map(selected, "user.sid");
        ctrl.updateSelectAll();
    }

    $onInit() {
        let ctrl = this;
        if (_.isUndefined(ctrl.selectedWorkers)) {
            ctrl.selectedWorkers = [];
        }

        const renderSearchBox = (renderOptions, isFirstRender) => {
            const { query, refine, clear, isSearchStalled, widgetParams } =
                renderOptions;
            if (isFirstRender) {
                ctrl.refine = refine;
            }
        };
        const customSearchBox = connectSearchBox(renderSearchBox);

        const renderHits = (renderOptions, isFirstRender) => {
            const { hits, isLastPage, showMore, results, currentPageHits } =
                renderOptions;
            let resultsByRelevance = [];
            for (let hit of hits) {
                let member = _.find(ctrl.workers, [
                    "growerMembership.sid",
                    hit.objectID,
                ]);
                if (member) {
                    resultsByRelevance.push(member);
                }
            }
            ctrl.filteredRoster = resultsByRelevance;
            ctrl.hasMore = !isLastPage;
            ctrl.showMore = showMore;
            ctrl.updateSelectAll();
            ctrl.$scope.$applyAsync();
        };
        const customHits = connectInfiniteHits(renderHits);

        function generateFilterString() {
            return "grower_organization_sid:" + ctrl.grower.sid;
        }

        ctrl.GrowerMembershipService.getAlgoliaKey().then(function (data) {
            const searchClient = algoliasearch(
                process.env.ALGOLIA_APP_ID,
                data.public_key,
            );
            const search = instantsearch({
                indexName: "grower_membership_index",
                searchClient,
            });

            search.addWidgets([
                customSearchBox({}),
                customHits({}),
                configure({
                    filters: generateFilterString(),
                }),
            ]);

            search.on("error", () => {});

            ctrl.searchInstance = search;
            ctrl.search();
        });
    }

    search() {
        let ctrl = this;
        if (_.isEmpty(ctrl.searchQuery)) {
            let ordering = ctrl.sortRules[_.get(ctrl, "orderBy", "first_name")];
            ctrl.filteredRoster = _.orderBy(
                ctrl.workers,
                ordering.func,
                ordering.order,
            );
            ctrl.updateSelectAll();
        } else {
            if (_.isUndefined(ctrl.refine)) {
                ctrl.searchInstance.start();
            }
            ctrl.refine(ctrl.searchQuery);
        }
    }

    update(results) {
        super.update(results);
        this.updateSelectAll();
    }
}

export default {
    bindings: {
        workers: "<",
        selectedWorkers: "<",
        onUpdate: "&",
        grower: "<",
    },
    controller: WorkerSelectMultiController,
    template: workerSelectTemplate,
    name: "workerSelect",
    translations: { en, es },
};
