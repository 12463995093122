"use strict";

import { ENGLISH, PRODUCTION, SPANISH } from "./app.constants";
import { homescreenState } from "./app.routes";
import { captureException } from "@sentry/browser";

/* @ngInject */
export default function configure(
    $locationProvider,
    $urlServiceProvider,
    $stateProvider,
    $translateProvider,
    $compileProvider,
    localStorageServiceProvider,
    $provide,
) {
    localStorageServiceProvider.setNotify(true, true);

    // URLs and Routing
    $locationProvider.html5Mode(true);
    $urlServiceProvider.config.strictMode(false);
    $urlServiceProvider.deferIntercept();
    $urlServiceProvider.rules.otherwise({ state: homescreenState });
    $provide.decorator("$uiViewScroll", function ($delegate) {
        return function (uiViewElement) {
            var top = uiViewElement[0].getBoundingClientRect().top;
            window.scrollTo(0, top - 63 - 52 - 16);
        };
    });

    $provide.decorator("$exceptionHandler", function ($delegate) {
        return function (exception, cause) {
            captureException(exception, {
                extra: {
                    cause: cause,
                },
            });
            $delegate(exception, cause);
        };
    });

    // Translations
    $translateProvider.registerAvailableLanguageKeys([SPANISH, ENGLISH], {
        "es_*": SPANISH,
        "en_*": ENGLISH,
        "*": ENGLISH,
    });
    $translateProvider.fallbackLanguage(ENGLISH);
    $translateProvider.determinePreferredLanguage();
    $translateProvider.useLocalStorage();
    $translateProvider.useMissingTranslationHandler(
        "handleMissingTranslationsFactory",
    );
    $translateProvider.useSanitizeValueStrategy("escape");

    $compileProvider.debugInfoEnabled(process.env.NODE_ENV !== PRODUCTION);
    $compileProvider.commentDirectivesEnabled(false);
    $compileProvider.cssClassDirectivesEnabled(false);
}
