import template from "./selected_objects_list.html";
import en from "./en.json";
import es from "./es.json";

export default {
    bindings: {
        objects: "<",
        selectedCountLabel: "@",
        ordering: "<",
    },
    template: template,
    name: "selectedObjectsList",
    translations: { en, es },
};
