import angular from "angular";
import _ from "lodash";
import {
    AccountService,
    AnnouncementService,
    AnnouncementTRANSLATEDService,
    AttachmentService,
    ConversationService,
    AccidentReportService,
    CropService,
    ExportFormatService,
    FarmMembershipService,
    FarmService,
    GroupManagementMembershipService,
    GroupManagementPermissionService,
    GroupService,
    GrowerMembershipService,
    GrowerOrganizationService,
    ManagementMembershipService,
    ManagementPermissionService,
    MembershipService,
    MessageService,
    ProfileService,
    SyncRecordService,
    ThemeService,
    TradeOrganizationService,
    AccidentReportTranslatedService,
    CommentService,
    CommentTRANSLATEDService,
    TrainingRequirementService,
    SignatureService,
    CredentialService,
    CredentialTypeService,
    SafetyCourseService,
    SafetyMeetingRequestService,
    SafetyMeetingTopicTRANSLATEDService,
    SafetyMeetingTopicService,
    SafetyMeetingService,
    DocumentPackageService,
    DocumentPackageRequestService,
    PacketService,
    IdentificationDocumentTypeService,
    OnboardingConfigurationService,
    OnboardingConfigurationTRANSLATEDService,
    DocumentPackageRecordService,
    SignatureRequestService,
    IdentificationDocumentService,
    NoticeSubscriptionService,
    PacketMemberService,
    AlertThemeService,
    AlertSubscriptionService,
    GrowerManagementMembershipService,
    GrowerManagementPermissionService,
    AuditTrailEventService,
    SafetyMeetingProgramElementService,
    SafetyMeetingProgramService,
    SafetyMeetingProgramSubscriptionService,
    TimeTrackingVendorConfigurationService,
    TimeTrackingEmployeeConfigurationService,
    TimeTrackingVendorService,
    ConversationNoteTranslatedService,
    ConversationNoteService,
    DocumentPackageTranslatedService,
    AdvisorConversationService,
    AdvisorSnippetService,
    AdvisorMessageService,
    H2ACandidateService,
    HousingSiteService,
    PublicTrainingService,
    HousingAssignmentService,
    DocumentPackageCustomizationService,
    DocumentPackageCustomizationTranslatedService,
    DisciplineReportService,
    DisciplineReportTranslatedService,
} from "./resources";

import {
    BeamsService,
    ExportEmployeeDataService,
    GuessGenderService,
    GuessLanguageService,
    ReadabilityService,
    SvixDashboardService,
    WAFLAContractService,
    ReplySuggestionService,
    StripePortalService,
    PaperworkSignatureService,
    LinkService,
    PaperworkQueryService,
    PollDownloadService,
} from "./nonresources";
import "angular-local-storage";

import restangular from "@priotas/restangular";
import { SID_LOOKUP } from "../../app.constants";
import translate from "angular-translate";
import { BAD_REQUEST, UNAUTHORIZED } from "../common/common.constants";
import { captureMessage, captureException } from "@sentry/browser";

let module = angular
    .module("app.api", ["LocalStorageModule", restangular, translate])
    .service("DisciplineReportService", DisciplineReportService)
    .service(
        "DisciplineReportTranslatedService",
        DisciplineReportTranslatedService,
    )
    .service("PollDownloadService", PollDownloadService)
    .service(
        "DocumentPackageCustomizationService",
        DocumentPackageCustomizationService,
    )
    .service(
        "DocumentPackageCustomizationTranslatedService",
        DocumentPackageCustomizationTranslatedService,
    )
    .service("PaperworkQueryService", PaperworkQueryService)
    .service("HousingAssignmentService", HousingAssignmentService)
    .service("PublicTrainingService", PublicTrainingService)
    .service("HousingSiteService", HousingSiteService)
    .service("H2ACandidateService", H2ACandidateService)
    .service("AdvisorSnippetService", AdvisorSnippetService)
    .service("AdvisorMessageService", AdvisorMessageService)
    .service("AdvisorConversationService", AdvisorConversationService)
    .service("LinkService", LinkService)
    .service("PaperworkSignatureService", PaperworkSignatureService)
    .service("StripePortalService", StripePortalService)
    .service("ReadabilityService", ReadabilityService)
    .service("GroupService", GroupService)
    .service(
        "GroupManagementMembershipService",
        GroupManagementMembershipService,
    )
    .service(
        "GroupManagementPermissionService",
        GroupManagementPermissionService,
    )
    .service("MembershipService", MembershipService)
    .service("FarmService", FarmService)
    .service("CropService", CropService)
    .service(
        "GrowerManagementMembershipService",
        GrowerManagementMembershipService,
    )
    .service(
        "GrowerManagementPermissionService",
        GrowerManagementPermissionService,
    )
    .service("ManagementPermissionService", ManagementPermissionService)
    .service("FarmMembershipService", FarmMembershipService)
    .service("ManagementMembershipService", ManagementMembershipService)
    .service("SyncRecordService", SyncRecordService)
    .service("ExportFormatService", ExportFormatService)
    .service("NoticeSubscriptionService", NoticeSubscriptionService)
    .service("GrowerMembershipService", GrowerMembershipService)
    .service("ThemeService", ThemeService)
    .service("TradeOrganizationService", TradeOrganizationService)
    .service("GrowerOrganizationService", GrowerOrganizationService)
    .service("ProfileService", ProfileService)
    .service("AccountService", AccountService)
    .service("GuessLanguageService", GuessLanguageService)
    .service("GuessGenderService", GuessGenderService)
    .service("BeamsService", BeamsService)
    .service("AnnouncementService", AnnouncementService)
    .service("MessageService", MessageService)
    .service("AnnouncementTRANSLATEDService", AnnouncementTRANSLATEDService)
    .service("AttachmentService", AttachmentService)
    .service("ConversationService", ConversationService)
    .service("AlertThemeService", AlertThemeService)
    .service("AlertSubscriptionService", AlertSubscriptionService)
    .service("SafetyMeetingService", SafetyMeetingService)
    .service("SafetyMeetingTopicService", SafetyMeetingTopicService)
    .service("SafetyMeetingRequestService", SafetyMeetingRequestService)
    .service(
        "SafetyMeetingTopicTRANSLATEDService",
        SafetyMeetingTopicTRANSLATEDService,
    )
    .service("AccidentReportTranslatedService", AccidentReportTranslatedService)
    .service("CommentService", CommentService)
    .service("CommentTRANSLATEDService", CommentTRANSLATEDService)
    .service("AccidentReportService", AccidentReportService)
    .service("SafetyCourseService", SafetyCourseService)
    .service("TrainingRequirementService", TrainingRequirementService)
    .service("SignatureService", SignatureService)
    .service("CredentialService", CredentialService)
    .service("CredentialTypeService", CredentialTypeService)
    .service("DocumentPackageService", DocumentPackageService)
    .service("DocumentPackageRequestService", DocumentPackageRequestService)
    .service("DocumentPackageRecordService", DocumentPackageRecordService)
    .service(
        "DocumentPackageTranslatedService",
        DocumentPackageTranslatedService,
    )
    .service("PacketService", PacketService)
    .service("SignatureRequestService", SignatureRequestService)
    .service("ExportEmployeeDataService", ExportEmployeeDataService)
    .service("IdentificationDocumentService", IdentificationDocumentService)
    .service(
        "IdentificationDocumentTypeService",
        IdentificationDocumentTypeService,
    )
    .service("OnboardingConfigurationService", OnboardingConfigurationService)
    .service(
        "OnboardingConfigurationTRANSLATEDService",
        OnboardingConfigurationTRANSLATEDService,
    )
    .service("PacketMemberService", PacketMemberService)
    .service("SvixDashboardService", SvixDashboardService)
    .service("AuditTrailEventService", AuditTrailEventService)
    .service("SafetyMeetingProgramService", SafetyMeetingProgramService)
    .service(
        "SafetyMeetingProgramElementService",
        SafetyMeetingProgramElementService,
    )
    .service(
        "SafetyMeetingProgramSubscriptionService",
        SafetyMeetingProgramSubscriptionService,
    )
    .service(
        "TimeTrackingVendorConfigurationService",
        TimeTrackingVendorConfigurationService,
    )
    .service(
        "TimeTrackingEmployeeConfigurationService",
        TimeTrackingEmployeeConfigurationService,
    )
    .service("TimeTrackingVendorService", TimeTrackingVendorService)
    .service("WAFLAContractService", WAFLAContractService)
    .service("ConversationNoteService", ConversationNoteService)
    .service(
        "ConversationNoteTranslatedService",
        ConversationNoteTranslatedService,
    )
    .service("ReplySuggestionService", ReplySuggestionService)
    .config(function (RestangularProvider, $translateProvider) {
        "ngInject";
        // REST
        RestangularProvider.setBaseUrl(process.env.API_DOMAIN + "/api/");
        RestangularProvider.setRestangularFields({
            id: SID_LOOKUP,
        });
        RestangularProvider.addFullRequestInterceptor(
            function (
                element,
                operation,
                route,
                url,
                headers,
                params,
                httpConfig,
            ) {
                headers["Accept-Language"] = $translateProvider.use();
                return {
                    element: element,
                    params: params,
                    headers: headers,
                    httpConfig: httpConfig,
                };
            },
        );
    })
    .run(function (Restangular, ToastService, AuthService, $http) {
        "ngInject";
        Restangular.setErrorInterceptor(
            function (response, deferred, responseHandler) {
                if (response.status === BAD_REQUEST) {
                    return true;
                } else if (response.status === UNAUTHORIZED) {
                    captureException("Unauthorized request", {
                        contexts: {
                            error: {
                                data: JSON.stringify(response.data),
                                status: response.status,
                                headers: response.config.headers,
                            },
                        },
                    });
                    AuthService.logout();
                    captureMessage("Logging out due to unauthorized request");
                    return false;
                } else if (response.status === -1) {
                    captureException("Harvust connection issue -1", {
                        contexts: {
                            error: {
                                data: JSON.stringify(response.data),
                                status: response.status,
                                headers: response.config.headers,
                            },
                        },
                    });
                    ToastService.create("common.ErrorService.CONNECTION_ISSUE");
                    return false;
                } else {
                    return true; // error not handled
                }
            },
        );
    });

export default module;
