"use strict";

import { formatDatetime } from "./common.utils";

export const DAYS_OF_THE_WEEK = [
    { value: "mon", label: "common.DAYS_OF_THE_WEEK.DAY_0" },
    { value: "tue", label: "common.DAYS_OF_THE_WEEK.DAY_1" },
    { value: "wed", label: "common.DAYS_OF_THE_WEEK.DAY_2" },
    { value: "thu", label: "common.DAYS_OF_THE_WEEK.DAY_3" },
    { value: "fri", label: "common.DAYS_OF_THE_WEEK.DAY_4" },
    { value: "sat", label: "common.DAYS_OF_THE_WEEK.DAY_5" },
    { value: "sun", label: "common.DAYS_OF_THE_WEEK.DAY_6" },
];

export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const UNPROCESSABLE_ENTITY = 422;
export const INTERNAL_SERVER_ERROR = 500;

export const COMMON_DATE_FORMAT = "MM/DD/YYYY";
export const ITEM = "item";
export const DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "hh:mm a";
export const DATETIME_FORMAT = formatDatetime(DATE_FORMAT, TIME_FORMAT);
export const FEATURE_PREVIEW_1 = 1;

export const DEFAULT_PROFILE_PICTURE_ID = "dpdjoplfunt8kowcro0f";

export const DAILY = "daily";
export const WEEKLY = "weekly";
export const MONTHLY = "monthly";
export const ANNUALY = "annually";

// Safety & training
export const MISSING = "missing";
export const PENDING = "pending";
export const TRAINED = "trained";
export const UNTRAINED = "untrained";
export const EXPIRING = "expiring";

export const EMAIL = "email";
export const SMS = "sms";
export const WHATSAPP = "whatsapp";
export const MALE = "male";
export const FEMALE = "female";

export const FACING_MODE_USER = "user";
export const FACING_MODE_ENVIRONMENT = "environment";
export const UPLOAD_PENDING = "pending";
export const UPLOAD_SUCCESS = "success";

export const EMPLOYEE_ACTIVE = "active";
export const EMPLOYEE_INACTIVE = "inactive";

export const CHARGE_AUTOMATICALLY = "charge_automatically";

export const RESOURCE_TYPE_ARTICLE = "article";
export const RESOURCE_TYPE_VIDEO = "video";
