"use strict";
export const ACCEPTED = "accepted";
export const QUEUED = "queued";
export const SENDING = "sending";
export const SENT = "sent";
export const DELIVERED = "delivered";
export const FAILED = "failed";
export const UNDELIVERED = "undelivered";
export const OPEN = "open";
export const DROPPED = "dropped";
export const DEFERRED = "deferred";
export const BOUNCE = "bounce";
export const VALID = "ok";
export const INVALID = "error";
export const RECEIVING = "receiving";
export const RECEIVED = "received";
export const READ = "read";
export const CANCELED = "canceled";

export const SENT_STATUS = "sent";
export const DRAFT_STATUS = "draft";
export const NOT_SENT_STATUS = "not sent";
export const SCHEDULED_STATUS = "scheduled";

export const SUCCESS_STATUSES = [DELIVERED, OPEN, SENT, READ];
export const FAILURE_STATUSES = [
    FAILED,
    UNDELIVERED,
    DROPPED,
    DEFERRED,
    BOUNCE,
];
export const ACTIVE = "active";
export const CLOSED = "closed";
