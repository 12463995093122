import template from "./resource_icon.html";
import {
    RESOURCE_TYPE_VIDEO,
    RESOURCE_TYPE_ARTICLE,
} from "../../common.constants";

class ResourceIconController {
    /* @ngInject */
    constructor() {
        this.RESOURCE_TYPE_VIDEO = RESOURCE_TYPE_VIDEO;
        this.RESOURCE_TYPE_ARTICLE = RESOURCE_TYPE_ARTICLE;
    }
}
export default {
    bindings: { type: "<" },
    name: "resourceIcon",
    controller: ResourceIconController,
    template: template,
};
