import _ from "lodash";
import moment from "moment";
import {
    CREW_FILTER,
    DATE_ADDED_END_FILTER,
    DATE_ADDED_START_FILTER,
    WORKER_STATUS_FILTER,
} from "../common/common.filters";
import { PENDING } from "../common/common.constants";

export const MEETING_FILTERS = {
    category: {
        func: function (element, test) {
            return element.meeting.category === test;
        },
    },
    delivery_type: {
        func: function (element, test) {
            return element.meeting.delivery_type === test;
        },
    },
    status: {
        func: function (element, test) {
            if (test === SCHEDULED_MEETING_STATUS) {
                return element.meeting.is_future;
            } else if (test === IN_PROGRESS_MEETING_STATUS) {
                return (
                    _.isNil(element.meeting.finish_date) &&
                    !element.meeting.is_future
                );
            } else if (test === FINISHED_MEETING_STATUS) {
                return !_.isNil(element.meeting.finish_date);
            }
        },
    },
    crew: {
        func: function (element, test) {
            return !_.isEmpty(_.intersection(element.meeting.crews, test));
        },
    },
};
export const SCHEDULED_MEETING_STATUS = "scheduled";
export const FINISHED_MEETING_STATUS = "finished";
export const IN_PROGRESS_MEETING_STATUS = "inprogress";
export const TRAINING_STATUS_FILTER = {
    func: function (element, test) {
        for (let status of element.topicStatuses) {
            if (test === PENDING) {
                if (status.pending) {
                    return true;
                }
            } else {
                if (status.requestStatus === test) {
                    return true;
                }
            }
        }
    },
};
export const TRAINING_REPORT_FILTERS = {
    status: WORKER_STATUS_FILTER,
};
export const DATE_SCREENED_START_FILTER = {
    func: function (element, test) {
        return moment(element.performed_date).isSameOrAfter(test);
    },
};
export const DATE_SCREENED_END_FILTER = {
    func: function (element, test) {
        return moment(element.performed_date).isSameOrBefore(test);
    },
};
export const SCREENING_STATUS_FILTER = {
    func: function (element, test) {
        return element.status === test;
    },
};

export const TOPIC_FILTERS = {
    administrator: {
        func: function (element, test) {
            return _.includes(test, element.topic.administrator);
        },
    },
    category: {
        func: function (element, test) {
            return test === element.topic.category;
        },
    },
};
