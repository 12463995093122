"use strict";

import "./tailwind.css";

import angular from "angular";
import configure from "./app.config";
import core from "./core.module";
import common from "./common";
import * as routesList from "./app.routes";
import setup from "./app.run";
import { FrameComponent, NavDropdownComponent } from "./app.components";
import "angular-local-storage";
import { captureMessage, init, replayIntegration } from "@sentry/browser";
import { extraErrorDataIntegration } from "@sentry/integrations";
import mixpanel from "mixpanel-browser";
import "oclazyload";
import { ENGLISH, SPANISH } from "./app.constants";
import en from "./en.translation.json";
import es from "./es.translation.json";
import ngAnimate from "angular-animate";
import supportedBrowsers from "./supportedBrowsers.js";
init({
    release: process.env.COMMIT_REF,
    dsn: process.env.SENTRY_PUBLIC_DSN,
    integrations: [
        extraErrorDataIntegration(),
        replayIntegration({
            maskAllText: false,
            blockAllMedia: true,
            networkDetailAllowUrls: [process.env.API_DOMAIN],
            networkResponseHeaders: ["Access-Control-Allow-Origin"],
        }),
    ],
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event) {
        if (supportedBrowsers.test(event.request.headers["User-Agent"])) {
            return event;
        }
    },
});

// throw new Error("My fun error")

mixpanel.init(process.env.MIXPANEL_TOKEN, {
    api_host: process.env.API_DOMAIN + "/mp",
});

export default angular
    .module("app", [
        core,
        common.name,
        "LocalStorageModule",
        "oc.lazyLoad",
        ngAnimate,
    ])
    .component("frameComponent", FrameComponent)
    .component("navDropdownComponent", NavDropdownComponent)
    .config(configure)
    .config(function ($stateProvider) {
        "ngInject";
        for (let route of Object.keys(routesList)) {
            $stateProvider.state(routesList[route]);
        }
    })
    .config(function ($translateProvider) {
        "ngInject";
        $translateProvider.translations(ENGLISH, en);
        $translateProvider.translations(SPANISH, es);
    })
    .factory("handleMissingTranslationsFactory", function () {
        return function (translationID) {
            captureMessage(
                "Translation for " + translationID + " doesn't exist",
            );
        };
    })
    .run(setup).name;
