import template from "./resources_card.html";
import en from "./en.json";
import es from "./es.json";

export default {
    template: template,
    bindings: {
        resources: "<",
    },
    name: "resourcesCard",
    translations: { en, es },
};
