import template from "./my_documents.html";
import en from "./en.json";
import es from "./es.json";
import _ from "lodash";
import {
    RECORD_SOURCE_EXTERNAL,
    RECORD_SOURCE_INTERNAL,
} from "../../constants";

class MyDocumentsController {
    /* @ngInject */
    constructor($translate) {
        this.$translate = $translate;
        this.EXTERNAL = RECORD_SOURCE_EXTERNAL;
        this.INTERNAL = RECORD_SOURCE_INTERNAL;
    }
}

export default {
    bindings: {
        paperworks: "<",
        grower: "<",
        myGrowerMembership: "<",
    },
    controller: MyDocumentsController,
    template: template,
    name: "myDocuments",
    translations: { en, es },
};
