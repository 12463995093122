"use strict";

import _ from "lodash";
import angular from "angular";
import appCore from "../../core.module";
import translationsEN from "./translations/en.json";
import translationsES from "./translations/es.json";
import { ENGLISH, SPANISH } from "../../app.constants";
import {
    ChangeLanguageComponent,
    FormActionComponent,
    SelectedSubjectsListComponent,
    EmployeeInfoComponent,
    sortComponent,
    inSortComponent,
    FilterComponent,
    SignatureComponent,
    TranslatedSnippetComponent,
    BreadcrumbComponent,
} from "./common.components";
import {
    actionCard,
    backButton,
    backImg,
    cardHeader,
    cfVideo,
    formTripleAction,
    lessonAudio,
    materialIcon,
    slimSelect,
    timePicker,
    passwordInput,
    profilePicture,
    radioInput,
    renderMarkdown,
    simpleBackButton,
    spinner,
    stateTitle,
    textareaInput,
    textInput,
    fileInput,
    languageAttribute,
    uiLink,
    anchorButton,
    stateProgressBar,
    stateMetaDescription,
    resourceLink,
    phoneNumberInput,
    otpInput,
} from "./common.directives";
import {
    DownloadService,
    ToastService,
    CanvasService,
} from "./common.services";
import restangular from "@priotas/restangular";
import checklistModel from "checklist-model";
import "angularjs-datepicker";
import components from "./components";

let module = angular
    .module("common.common", [
        appCore,
        restangular,
        checklistModel,
        "720kb.datepicker",
    ])
    .directive("otpInput", otpInput)
    .directive("phoneNumberInput", phoneNumberInput)
    .directive("resourceLink", resourceLink)
    .directive("stateMetaDescription", stateMetaDescription)
    .directive("fileInput", fileInput)
    .directive("cfVideo", cfVideo)
    .directive("backImg", backImg)
    .directive("textInput", textInput)
    .directive("passwordInput", passwordInput)
    .directive("textareaInput", textareaInput)
    .directive("radioInput", radioInput)
    .directive("cardHeader", cardHeader)
    .directive("actionCard", actionCard)
    .directive("backButton", backButton)
    .directive("profilePicture", profilePicture)
    .directive("slimSelect", slimSelect)
    .directive("timePicker", timePicker)
    .directive("simpleBackButton", simpleBackButton)
    .directive("lessonAudio", lessonAudio)
    .directive("stateTitle", stateTitle)
    .directive("spinner", spinner)
    .directive("formTripleAction", formTripleAction)
    .directive("renderMarkdown", renderMarkdown)
    .directive("materialIcon", materialIcon)
    .directive("languageAttribute", languageAttribute)
    .directive("uiLink", uiLink)
    .directive("anchorButton", anchorButton)
    .directive("stateProgressBar", stateProgressBar)
    .component("changeLanguage", ChangeLanguageComponent)
    .component("formAction", FormActionComponent)
    .component("selectedSubjectsList", SelectedSubjectsListComponent)
    .component("employeeInfo", EmployeeInfoComponent)
    .component("sortData", sortComponent)
    .component("inSortData", inSortComponent)
    .component("filterData", FilterComponent)
    .component(SignatureComponent.name, SignatureComponent)
    .component(TranslatedSnippetComponent.name, TranslatedSnippetComponent)
    .component(BreadcrumbComponent.name, BreadcrumbComponent)
    .service("ToastService", ToastService)
    .service("DownloadService", DownloadService)
    .service("CanvasService", CanvasService);

_.forEach(components, function (component) {
    module.component(component.name, component);
});

module.config(function ($translateProvider) {
    "ngInject";

    _.forEach(components, function (component) {
        _.forEach(component.translations, function (table, language) {
            $translateProvider.translations(language, table);
        });
    });
    $translateProvider.translations(ENGLISH, translationsEN);
    $translateProvider.translations(SPANISH, translationsES);
});

export default module;
